import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { CheckboxVisibility, CommandBar, Image, IColumn, ICommandBarItemProps, ImageFit, Panel, Selection, SelectionMode, Stack, Text, ActionButton, Modal, FontWeights, mergeStyleSets, getTheme } from '@fluentui/react';
import { useAccount, useMsal } from "@azure/msal-react";
import { useMediaQuery } from 'react-responsive';
import Loader from '../../../components/Loader';
import { GetPriceDisplayString, GetTournamentLocalTime, GetTournamentStartAndEndDates } from '../../../Display';
import { exportDivisionRules, getAvailableTimezones, getCategories, getCoupons, getTournament, ICoupon } from '../../../ApiService';
import List, { IDocument } from '../../../components/List';
import { Tournament, TournamentType } from '../../../model/Tournament';
import CreateCouponDialog from './CreateCouponDialog';
import { useBoolean } from '@fluentui/react-hooks';
import TournamentWizard from '../../../components/TournamentWizard';
import { ITimezoneViewModel } from '../../../model/TimezoneViewModel';
import { Category } from '../../../model/Category';
import { useNavigate } from 'react-router-dom';
import FileDownloader from '../../../components/FileDownloader';
import { IFileDownload } from '../../../model/FileDownload';
import DivisionRuleImporter from '../../../components/DivisionRuleImporter';

interface IData {
    tournamentId: string;
}

interface ICouponRow extends IDocument {
    id: string;
    expiry: string;
    value: number;
    actualRedemptions: number;
    maxRedemptions: number | undefined;
    maxRedemptionsPerMembership: number | undefined;
}

const TournamentSettings: FC<IData> = ({ tournamentId }) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const navigate = useNavigate();
    
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    const [tournament, setTournament] = useState<Tournament>();
    const [couponItems, setCouponItems] = useState<ICouponRow[]>();

    useEffect(() => {
        const fetchAsync = async () => {
            var tournament = await getTournament(instance, account!, tournamentId);

            //when editing a tournament we need to use the raw pricing strategies that we are sent as the other pricing strategies will get filtered out
            //if they have expired or are not yet active which is bad for the edit experience
            tournament.PricingStrategies = tournament.RawPricingStrategies;
            
            setTournament(tournament);
        }

        fetchAsync();
    }, [tournamentId, account, instance]);

    useEffect(() => {
        const fetchAsync = async () => {
            var coupons = await getCoupons(instance, account!, tournamentId);
            
            var couponItems: ICouponRow[] = [];

            coupons.forEach((coupon: ICoupon) => {
                couponItems.push({
                    key: coupon.Id,
                    getTitle: () => { return coupon.Id; },
                    id: coupon.Id,
                    expiry: coupon.Expiry === undefined ? "No Expiry" : GetTournamentLocalTime(coupon.Expiry, tournament!.Timezone),
                    value: coupon.Value,
                    actualRedemptions: coupon.ActualRedemptions,
                    maxRedemptions: coupon.MaxRedemptions,
                    maxRedemptionsPerMembership: coupon.MaxRedemptionsPerMembership
                });
            });

            setCouponItems(couponItems);
        }

        if (tournament === undefined) {
            return;
        }

        fetchAsync();
    }, [tournament, instance, account, tournamentId]);

    const [couponColumns,] = useState<IColumn[]>([
        {
            key: 'column1',
            name: 'Code',
            fieldName: 'id',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: 'column2',
            name: 'Expiry',
            fieldName: 'expiry',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: 'column3',
            name: 'Value',
            fieldName: 'value',
            minWidth: 50,
            maxWidth: 75,
            isResizable: true,
            onRender: (item: ICouponRow) => <Text>{GetPriceDisplayString(item.value)}</Text>
        },
        {
            key: 'column3a',
            name: 'Actual Redemptions',
            fieldName: 'actualRedemptions',
            minWidth: 50,
            maxWidth: 75,
            isResizable: true
        },
        {
            key: 'column4',
            name: 'Max Redemptions',
            fieldName: 'maxRedemptions',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            onRender: (item: ICouponRow) => <Text>{item.maxRedemptions === undefined ? 'Unlimited' : item.maxRedemptions}</Text>
        },
        {
            key: 'column5',
            name: 'Max Redemptions Per Membership',
            fieldName: 'maxRedemptionsPerMembership',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: (item: ICouponRow) => <Text>{item.maxRedemptionsPerMembership === undefined ? 'Unlimited' : item.maxRedemptionsPerMembership}</Text>
        },
    ]);

    const [couponSelection, setCouponSelection] = useState<Selection>(new Selection());
    const [selectedCouponRow, setSelectedCouponRow] = useState<ICouponRow>();

    const onCouponActiveItemChanged = (item?: ICouponRow, index?: number) => {
        if (couponSelection === undefined) { //do nothing to keep linter happy
            setSelectedCouponRow(undefined);
        }
        else {
            setSelectedCouponRow(item!);
        }
    }

    const copyCoupon = () => {
        if (selectedCouponRow === undefined) {
            return;
        }

        navigator.clipboard.writeText(selectedCouponRow?.id);
        alert('Coupon code copied to clipboard');
    }

    const startDownload = async (): Promise<number | IFileDownload> => {        
        return exportDivisionRules(instance, account!, tournamentId);
    }

    const [isDownloadModalOpen, { setTrue: showDownloadModal, setFalse: hideDownloadModal }] = useBoolean(false);
    const [isImportModalOpen, { setTrue: showImportModal, setFalse: hideImportModal }] = useBoolean(false);

    const divisionRulesNearCommandBarItems: ICommandBarItemProps[] = [{
        key: 'export',
        text: 'Export Division Rules',
        iconProps: { iconName: 'Download' },
        disabled: tournament === undefined,
        onClick: () => showDownloadModal()
    },
    {
        key: 'import',
        text: 'Import Division Rules',
        iconProps: { iconName: 'Upload' },
        disabled: tournament === undefined,
        onClick: () => {
            // Trigger the file dialog
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = '.xlsx';
            fileInput.addEventListener('change', (event: unknown) => handleImport(event as ChangeEvent<HTMLInputElement>));
            fileInput.click();
        }
        }];
    
    const [importFile, setImportFile] = useState<File | undefined>();

    const handleImport = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        setImportFile(file);
        showImportModal();
    };

    const nearCommandBarItems: ICommandBarItemProps[] = [{
        key: 'new',
        text: 'New Coupon',
        iconProps: { iconName: 'add' },
        disabled: false,
        onClick: () => showNewCouponModal()
    },
    {
        key: 'copyCoupon',
        text: 'Copy Coupon',
        iconProps: { iconName: 'Copy' },
        disabled: selectedCouponRow === undefined,
        onClick: () => copyCoupon()
        }];
    
    const onCouponCreated = (coupon: ICoupon) => {
        setCouponItems([...couponItems!, {
            key: coupon.Id,
            getTitle: () => { return coupon.Id; },
            id: coupon.Id,
            expiry: coupon.Expiry === undefined ? "No Expiry" : GetTournamentLocalTime(coupon.Expiry, tournament!.Timezone),
            value: coupon.Value,
            actualRedemptions: coupon.ActualRedemptions,
            maxRedemptions: coupon.MaxRedemptions,
            maxRedemptionsPerMembership: coupon.MaxRedemptionsPerMembership
        }]);

        hideNewCouponModal();
    }

    const [isNewCouponModalOpen, { setTrue: showNewCouponModal, setFalse: hideNewCouponModal }] = useBoolean(false);
    const [isEditTournamentModalOpen, { setTrue: showEditTournamentModal, setFalse: hideEditTournamentModal }] = useBoolean(false);

    const [allTz, setAllTz] = useState<ITimezoneViewModel[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    
    const onEditTournament = async () => {
        showEditTournamentModal();

        var tzs = await getAvailableTimezones(instance, account!);
        setAllTz(tzs);
  
        var categories = await getCategories(instance, account!);
        setCategories(categories);
    }
    
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 0px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });
    
    return (
          <AuthenticatedTemplate>
            <div style={{ marginTop:40, minWidth: isMobile ? 100 : 400 }}>                
                
                <Stack tokens={{childrenGap:10}}>
                    <Text variant='large'>Tournament</Text>
                    <br />
                    {tournament === undefined ? <Loader Text='Just a moment...' /> :
                        <Stack horizontalAlign='start' tokens={{childrenGap:20}}>                            
                            <div style={{ textAlign: 'center', backgroundColor: 'white', padding: 20 }}>                                
                                <Stack horizontalAlign='center'>
                                    <Image src={tournament.ImageUri} width={165} imageFit={ImageFit.contain} />
                                </Stack>
                                <Text variant='medium'>{tournament.Title}</Text>
                                <br />                            
                                <Text variant='small'>{GetTournamentStartAndEndDates(tournament.StartDate!, tournament.EndDate!, tournament.Timezone)}</Text>
                                {tournament.Type === TournamentType.InPerson ? <><br /><Text variant='small'>{tournament.Location}</Text></> : null}
                                <br />
                                <ActionButton iconProps={{iconName:'View'}} text='View' onClick={() => navigate(`/event/${tournament.Id}`)} />
                                <ActionButton iconProps={{iconName:'Edit'}} text='Edit' onClick={onEditTournament} />
                            </div>                            
                        </Stack>                        
                    }
                    <br /><br />
                    <Text variant='large'>Division Rules</Text>
                    <br />
                    <CommandBar
                        items={divisionRulesNearCommandBarItems}
                        farItems={[]} />
                    <br />
                    {tournament === undefined && <Loader Text='Just a moment...' />}
                    {tournament !== undefined && <>
                        <Text variant='medium'>Rules last updated: {tournament.DivisionRulesModified === undefined ?
                            GetTournamentLocalTime(tournament.Created, tournament.Timezone) :
                            GetTournamentLocalTime(tournament.DivisionRulesModified, tournament.Timezone)}</Text>
                    </>}
                    
                    <br /><br />
                    <Text variant='large'>Coupons</Text>
                    <br />
                    <CommandBar
                        items={nearCommandBarItems}
                        farItems={[]} />
                    {couponItems === undefined && <Loader Text='Just a moment...' />}
                    {couponItems !== undefined && couponItems.length === 0 && <Text>No coupons available</Text>}
                    {couponItems !== undefined && couponItems.length > 0 && 
                        <List
                            selectionMode={SelectionMode.single}  
                            enableSort                       
                            checkboxVisibility={CheckboxVisibility.hidden}
                            onActiveItemChanged={onCouponActiveItemChanged} 
                            onSelectionChanged={(s)=>setCouponSelection(s)}    
                            items={couponItems}
                            columns={couponColumns}/>                            
                    }                    
                </Stack>
                <Panel
                    headerText='Create Coupon'
                    isOpen={isNewCouponModalOpen}
                    isLightDismiss={false}
                    onDismiss={hideNewCouponModal}
                    closeButtonAriaLabel="Close">
                    <CreateCouponDialog
                        tournamentId={tournamentId}
                        onCompleted={onCouponCreated} />
                </Panel>
                <Panel
                    headerText='Edit Tournament'
                    isOpen={isEditTournamentModalOpen}
                    isLightDismiss={false}
                    onDismiss={hideEditTournamentModal}
                    closeButtonAriaLabel="Close">
                    {(tournament === undefined || categories === undefined || allTz === undefined) ? <Loader Text='Just a moment...' /> : 
                        <TournamentWizard
                            editTournament={tournament}
                            categories={categories}
                            timezones={allTz} />
                    }
                </Panel>
                <Modal
                    isOpen={isDownloadModalOpen}
                    onDismiss={() => { hideDownloadModal();}}            
                    containerClassName={contentStyles.container}>
                    <FileDownloader 
                        loadingMessage='Downloading rules, just a moment...'
                        startDownload={startDownload} />
                </Modal>

                <Modal
                    isOpen={isImportModalOpen}
                    onDismiss={() => hideImportModal()}
                    containerClassName={contentStyles.container}>
                    <DivisionRuleImporter 
                        tournament={tournament!}
                        file={importFile!} />
                </Modal>
            </div>
          </AuthenticatedTemplate>
    )
}

export default TournamentSettings;