import { ICustomValue } from "./Student";

export enum InputType {
    String = 0,
    Number = 1
}

export interface IMembershipInput {
    StudentId: string;
    Weight?: number;
    CustomValues?: ICustomValue[];
}

export interface IUserInputOption {
    Id: string;
    Label: string;
}

export interface IUserInput {
    BindTo: string;
    Type: InputType;
    Label: string;
    Description: string;
    IsRequired: boolean;    
    Options: IUserInputOption[];
    DataValidator?: string;
}

export interface IUserInputWithStudents extends IUserInput {
    StudentIds: string[];
    TournamentId: string;
}

export interface ICatalogItemInputWithValue extends IUserInput {
    Value: string;    
}

export interface ICatalogItem {    
    Id: string;
    Name: string;
    Description: string;
    ImageUri: string;
    Price: number;
    IsTaxed: boolean;
    IsSuggested: boolean;
    Inputs: IUserInput[];
}

export interface ICatalogItemWithQuantity extends ICatalogItem {
    CatalogItemId: string;
    AvailableQuantity: number;
    AdditionalTerms: string[];
}

export class CatalogItem implements ICatalogItem {
    Id: string;
    Name: string;
    Description: string;
    ImageUri: string;
    Price: number;
    IsTaxed: boolean;
    IsSuggested: boolean;
    Inputs: IUserInput[];

    constructor(
        params: ICatalogItem = {} as ICatalogItem) {
            
        let {
            Id = "",
            Name = "",
            Description = "",
            ImageUri = "",
            Price = -1,
            IsTaxed = false,
            IsSuggested = false,
            Inputs = []
        } = params;
            
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.ImageUri = ImageUri;
        this.Price = Price;
        this.IsTaxed = IsTaxed;
        this.IsSuggested = IsSuggested;
        this.Inputs = Inputs;
    }
}