import React, { useEffect, useState, KeyboardEventHandler } from "react";
import { getViralJoinEnv, postCommunityJoinRequest } from "../../ApiService";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {
    Checkbox,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Label,
    Panel,
    PanelType,
    PrimaryButton,
    Stack,
    Text
  } from '@fluentui/react';
import { UserInvitation } from "../../model/UserInvitation";
import { ViralJoinEnv } from "../../model/ViralJoinEnv";
import StudentEditor from "../../components/StudentEditor";
import { Student } from "../../model/Student";
import Loader from "../../components/Loader";
import { useBoolean } from '@fluentui/react-hooks';
import { useNavigate } from "react-router-dom";
import { TrainingFacility } from "../../model/TrainingFacility";
import { InputActionMeta, SingleValue } from "react-select";
import StudentCard from "../../components/StudentCard";
import AddStudentCard from "../../components/AddStudentCard";
import UserAlreadyExists from "./UserAlreadyExists";
import { ValidateEmailField } from "../../Validation";

//we can send someone to this page with a generic invitation code
//or they can come in without a code

//the community can have logic that validates the code, ie: AAU

const JoinCommunity = () => {
    const navigate = useNavigate();
    const [env, setEnv] = useState<ViralJoinEnv>();
    const [userInvitation, setUserInvitation] = useState<UserInvitation>(new UserInvitation());
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAddStudentOpen, { setTrue: openAddStudentPanel, setFalse: dismissAddStudentPanel }] = useBoolean(false);
    const [isViralJoinEnabled, setIsViralJoinEnabled] = useState<boolean>();
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean>(false);


    useEffect(() => {
        
        const fetchAsync = async () => {
            var env = await getViralJoinEnv();

            if (env !== undefined) {
                var options: Option[] = [];

                for (let tf of env.TrainingFacilities) {
                    options.push({ label: tf.Title, value: tf.Id });
                }

                setFacilityOptions(options);

                if (!env.CanAddTrainingFacility && env.TrainingFacilities.length === 1) {
                    setUserInvitation({ ...userInvitation, TrainingFacility: env.TrainingFacilities[0] });
                }
            }

            setIsViralJoinEnabled(env !== undefined);
            setEnv(env);
            setIsLoading(false);
        }

        fetchAsync();
        // eslint-disable-next-line
    }, []);

    const [facilityOptions, setFacilityOptions] = useState<Option[]>();
    const [canAddStudent, setCanAddStudent] = useState<boolean>(false);
    const [pendingStudent, setPendingStudent] = useState<Student>();
    const [isCreateConflict, setIsCreateConflict] = useState<boolean>(false);

    const studentValidationStatusChanged = (student: Student, isValid: boolean) => {
        setCanAddStudent(isValid);
        setPendingStudent(student);
    }

    const addStudent = () => {
        
        userInvitation.Students.push(pendingStudent!);
        setUserInvitation({ ...userInvitation });
        setPendingStudent(undefined);
        setCanAddStudent(false);
        dismissAddStudentPanel();
    }

    const joinCommunity = async () => {
        var r = await postCommunityJoinRequest(userInvitation);
        
        if (r?.ok) {
            navigate('/pending');
        }
        else {
            setIsJoining(false);

            if (r?.status === 409) {
                setIsCreateConflict(true);
                return;
            }

            alert("Sorry, something went wrong. Please try again.");
        }
    }

    const getTrainingFacility = (facilityTitle: string): TrainingFacility => {
        var foundFacility = env?.TrainingFacilities.find(f => f.Title === facilityTitle);

        if (foundFacility !== undefined) {
            return foundFacility;
        }

        return new TrainingFacility({ Id: '', Title: facilityTitle, Location: '' });
    }
    
    interface Option {
        readonly label: string;
        readonly value: string;
    }
      
    const createOption = (label: string) => ({
        label,
        value: label,
    });

    const [inputValue, setInputValue] = React.useState('');
    const [value, setValue] = React.useState<readonly Option[]>([]);

    useEffect(() => {
        let emails = value.flatMap(v => v.value.split(',')); //split on comma incase someone enters multiple        

        setUserInvitation(userInvitation => ({ ...userInvitation, Emails: emails }));
    }, [value]);

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setValue((prev) => [...prev, createOption(inputValue)]);
                setInputValue('');
                event.preventDefault();
        }
    };

    const handleBlur = () => {
        const label = inputValue?.trim() || "";
        const option = { label, value: label };
        setValue([...(value || []), option]);
        setInputValue("");
    };
    
    const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === "input-change") {
            setInputValue(newValue.toLowerCase());

            if (newValue.trim().length === 0) {
                return;
            }
        }
        if (actionMeta.prevInputValue.length > 0 &&
            actionMeta.action === "input-blur") {
            handleBlur();
        }
    };

    const onChangeTrainingFacility = (newValue: SingleValue<Option>) => {
        if (newValue === null) {
            setUserInvitation(userInvitation => ({ ...userInvitation, TrainingFacility: undefined }))
            return;
        }

        setUserInvitation(userInvitation => ({ ...userInvitation, TrainingFacility: getTrainingFacility(newValue!.label) }))
    }

    const [isJoining, setIsJoining] = useState<boolean>(false);
          
    const onJoin = () => {
        if (userInvitation === undefined ||
            userInvitation.Students.length === 0 ||
            userInvitation.Emails.length === 0 ||
            !userInvitation.Emails.every(e=> ValidateEmailField(e)) ||
            userInvitation.TrainingFacility === undefined ||
            !hasAcceptedTerms) {
            toggleHideDialog();
        }
        else {
            setIsJoining(true);
            joinCommunity();
        }
    }

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const dialogStyles = { main: { maxWidth: 450 } };
     
     
    const getSubtext = () => {
        if (userInvitation.Students.length === 0) {
            return "Please add one or more members before proceeding.";
        }

        if (userInvitation.Emails.length === 0) {
            return "Please add one or more email addresses before proceeding.";
        }

        if (!userInvitation.Emails.every(e=> ValidateEmailField(e))) {
            return "Please ensure that all email addresses are valid before proceeding";
        }

        if (userInvitation.TrainingFacility === undefined) {
            return "Please enter your training facility before proceeding.";
        }

        if (!hasAcceptedTerms) {
            return "Please check the box to acknowledge that you have read the privacy policy and agree to the terms of use.";
        }
    }

    const dialogContentProps = {
        type: DialogType.normal,
        title: "Unable to join",
        closeButtonAriaLabel: 'Close',
        subText: getSubtext(),
    };
      
    return (
        <>
            {isLoading ?
                <Loader Text="Just a moment" />
                :
                isViralJoinEnabled ?
                    isCreateConflict ?
                        <UserAlreadyExists emails={userInvitation.Emails} /> :
                        <>
                            <br /><br />
                            <div style={{ padding: 40, backgroundColor: 'white', maxWidth: 750, minWidth: 200, marginLeft: 'auto', marginRight: 'auto' }}>
                                <Text variant='large'>Welcome to {env?.Title}</Text>
                                <br /><br />
                                <Text variant="medium">To get started, we'll need the best email address to contact you. If there are multiple members of your family who will login separately, include those email addresses here also.</Text>
                                <br /><br />
                                <Label required>Email(s)</Label>

                                <CreatableSelect
                                    components={{ DropdownIndicator: null }}
                                    inputValue={inputValue}
                                    isClearable
                                    isMulti
                                    blurInputOnSelect
                                    escapeClearsValue={false}
                                    menuIsOpen={false}
                                    onChange={(newValue) => setValue(newValue)}
                                    onInputChange={onInputChange}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Enter one or more email addresses..."
                                    value={value}
                                />
                        
                                <br />                                
                                <Label required>Training Facility</Label>

                                {env?.CanAddTrainingFacility ? 
                                    <CreatableSelect                                            
                                        isClearable
                                        options={facilityOptions}
                                        onChange={(newValue) => onChangeTrainingFacility(newValue)}
                                        placeholder="Where do you train? (type to add new location)"                                       
                                    />
                                    : 
                                    <Select
                                        options={facilityOptions}                                    
                                        onChange={(newValue) => onChangeTrainingFacility(newValue)}
                                        placeholder="Where do you train?"
                                    />
                                }
                                
                
                                <br />
                                <Label required>Members</Label>
                                <br />
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    {userInvitation.Students.map((s, idx) => {
                                        return (
                                            <Stack.Item key={idx}>
                                                <StudentCard
                                                    imageUri={s.ImageUri}
                                                    canClick={false}
                                                    initials={`${s.FirstName[0]}${s.LastName[0]}`}
                                                    level={env!.Levels.find(l => l.Id === s.LevelId)!}
                                                    studentName={s.FirstName}
                                                />
                                            </Stack.Item>
                                        )
                                    })
                                    }
                                    <Stack.Item>
                                        <AddStudentCard onClick={openAddStudentPanel} />
                                    </Stack.Item>
                                </Stack>
                        
                                <br />
                                {env === undefined ?
                                    null :
                                    <Panel
                                        headerText="Add Member"
                                        type={PanelType.smallFixedFar}
                                        isOpen={isAddStudentOpen}
                                        isLightDismiss={true}
                                        onDismiss={dismissAddStudentPanel}
                                        closeButtonAriaLabel="Close">
                                        <StudentEditor
                                            levels={env.Levels}
                                            requestGender={env.RequestStudentGender}
                                            requestDob={env.RequestStudentDob}
                                            programs={env.Programs}
                                            onModelValidationStatusChanged={studentValidationStatusChanged} />
                                        <br />
                                        <Stack horizontalAlign="end">
                                            <DefaultButton disabled={!canAddStudent} onClick={addStudent}>Add</DefaultButton>
                                        </Stack>
                                    </Panel>
                                }
                                <br /><br />
                                <Stack tokens={{ childrenGap: 20 }}>
                                    <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 5 }}>
                                        <Checkbox onChange={(ev, checked) => setUserInvitation(userInvitation => ({ ...userInvitation, MarketingConsent: checked! }))} /><Text variant="medium">Yes, please send occasional emails about future events that may be of interest.</Text>
                                    </Stack>
                                    <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 5 }}>
                                        <Checkbox onChange={(ev, checked) => setHasAcceptedTerms(checked ?? false)} /><Text variant="medium">I have read the <a href='https://www.ninjapanel.com/privacy.html' target="_blank" rel="noreferrer">privacy policy</a> and agree to the <a href='https://www.ninjapanel.com/terms.html' target="_blank" rel="noreferrer">terms of use</a>.</Text>
                                    </Stack>
                                    <Stack horizontalAlign="center">
                                        <PrimaryButton disabled={isJoining} style={{ width: 200 }} onClick={onJoin}>Join</PrimaryButton>
                                        {isJoining && <Loader Text="Just a moment..." />}
                                    </Stack>
                                </Stack>
                            </div>
                    
                        </>
                    :
                    <>
                        <br />
                        <div style={{ padding: 40, backgroundColor: 'white' }}>
                            <Stack horizontalAlign="center">
                                <Text variant='large'>Hmm, we couldn't find what you were looking for.</Text>
                            </Stack>
                        </div>
                    </>
            }
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{ styles: dialogStyles }}>
                <DialogFooter>
                    <PrimaryButton onClick={toggleHideDialog} text="Ok" />
                </DialogFooter>
            </Dialog>
        </>        
    );
}

export default JoinCommunity;