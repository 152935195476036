import React, { FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Label, Pivot, PivotItem, Stack, Text } from '@fluentui/react';
import { getTournamentSummary } from '../../../ApiService';

import { useAccount, useMsal } from "@azure/msal-react";
import { useMediaQuery } from 'react-responsive';
import { TournamentSummary } from '../../../model/TournamentSummary';
import Loader from '../../../components/Loader';
import { GetPriceDisplayString } from '../../../Display';
import { DateTime } from 'luxon';

interface IData {
    tournamentId: string;
}

const Summary : FC<IData> = ({tournamentId}) => {  
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    const [tournamentSummary, setTournamentSummary] = useState<TournamentSummary>();

    const [errorCode, setErrorCode] = useState<number>();

    useEffect(()=> {
      if(errorCode === undefined) {
        return;
      }

      throw new Error("The server returned status code: " + errorCode);
    },[errorCode]);

    useEffect(()=> {
        const fetchAsync = async () => {
            var summary = await getTournamentSummary(instance, account!,tournamentId);
            
            if(typeof summary === 'number') {
                setErrorCode(summary);
                return;
            }
            
            setTournamentSummary(summary);
        }

        fetchAsync();        
    }, [tournamentId, account, instance]);
    
    const getDisbursementTotal = () => {
        var total = 0;
        if(tournamentSummary?.Disbursements !== undefined) {
            tournamentSummary?.Disbursements.forEach(d => {
                total += (d.Amount - d.AmountWitheld);
            });
        }
        return total;
    }
    
    return (
          <AuthenticatedTemplate>
            <div style={{ marginTop:40, minWidth: isMobile ? 100 : 400 }}>
                {tournamentSummary === undefined ?
                    <Loader Text='Just a moment...' /> :
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack style={{backgroundColor:'white', padding:20, width:500, marginLeft:'auto', marginRight:'auto'}}>
                            <Text variant='large' style={{ textAlign: 'center' }}>Financials</Text>
                            <br />
                            <Stack horizontal tokens={{childrenGap:40}} horizontalAlign='center'>
                                <Stack>
                                    <Label>Total Collected</Label>
                                    <Text variant='medium'>{GetPriceDisplayString(tournamentSummary?.TotalCollected)}</Text>
                                </Stack>
                                <Stack>
                                    <Label>Total Disbursed</Label>
                                    <Text variant='medium'>{GetPriceDisplayString(getDisbursementTotal())}</Text>
                                </Stack>
                                <Stack>
                                    <Label>Platform Fees</Label>
                                    <Text variant='medium'>{GetPriceDisplayString(tournamentSummary?.NpnlFees)}</Text>
                                </Stack>
                            </Stack>
                            <br />
                            <Text variant='large' style={{ textAlign: 'center' }}>Registrations</Text>
                            <br />
                            <Pivot>
                                <PivotItem headerText='Roles'>
                                {tournamentSummary?.Roles === undefined || tournamentSummary?.Roles.length === 0 ?
                                <Text variant='smallPlus'>None yet.</Text> :
                                    tournamentSummary?.Roles.map((r, idx) =>
                                    <Stack key={idx}>
                                        <Label>{r.Role}</Label>
                                        <Text variant='medium'>{r.NumMembers}</Text>
                                    </Stack>)}
                                </PivotItem>
                                <PivotItem headerText='Events'>
                                {tournamentSummary?.Events === undefined || tournamentSummary?.Events.length === 0 ?
                                <Text variant='smallPlus'>None yet.</Text> :
                                tournamentSummary?.Events.map((e, idx) =>
                                <Stack key={idx}>
                                    <Label>{e.EventName}</Label>
                                    <Text variant='medium'>{e.NumAthletes} athletes {e.DivisionsWithoutMatch !== null && e.DivisionsWithoutMatch?.length > 0 ? "({e.DivisionsWithoutMatch.length} currently unmatched)" : ""}</Text>
                                </Stack>)}
                                </PivotItem>
                                <PivotItem headerText='Catalog'>
                                {tournamentSummary?.Catalog === undefined || tournamentSummary?.Catalog.length === 0 ?
                                <Text variant='smallPlus'>None yet.</Text> :
                                tournamentSummary?.Catalog.map((e, idx) =>
                                <Stack key={idx}>
                                        <Label>{e.ItemName}</Label>
                                        <Text variant='small'>{e.Configuration}</Text>
                                        <Text variant='medium'>{e.NumPurchases} purchased</Text>
                                </Stack>)}
                                </PivotItem>
                            </Pivot>
                            
                            
                            <br />
                            
                            <br />
                            <Text variant='large' style={{ textAlign: 'center' }}>Disbursements</Text>
                            <br />
                                {tournamentSummary.Disbursements === undefined || tournamentSummary.Disbursements.length === 0 ?
                                <Text variant='smallPlus'>None yet.</Text> :
                                tournamentSummary?.Disbursements.map((d, idx) =>
                                <Stack key={idx}>
                                        <Label>{DateTime.fromJSDate(d.PeriodStart).toLocaleString()} - {DateTime.fromJSDate(d.PeriodEnd).toLocaleString()}</Label>
                                        <Text variant='medium'>Amount: {GetPriceDisplayString(d.Amount - d.AmountWitheld)} (via {d.Method}). Held in reserve: {GetPriceDisplayString(d.AmountWitheld)}</Text>
                                </Stack>)}
                        </Stack>                                                   
                    </Stack>
                }
            </div>
          </AuthenticatedTemplate>
    )
}

export default Summary;