import { ILevel } from "./Level";
import { StudentPromotion } from "./StudentPromotion";

export interface ICustomValue {
    Key: string;
    Value: string;
}
export interface IStudent {
    Id: string;
    FirstName: string;
    LastName: string;
    DateOfBirth?: Date;
    Gender?: Gender;
    ImageUri: string;
    Level?: ILevel;    
    LevelId: string;
    Status: StudentStatus;
    ProgramIds: string[];
    PromotionHistory?: StudentPromotion[];
    Weight: number;
    CustomValues: ICustomValue[];
    TrainingFacility: string;
    SpecialNeeds: boolean;
    EventRegistrations?: string[];
}

export enum StudentStatus {
    Active = 0,
    Canceled = 1,
    Suspended = 2,
    Archived = 3
}

export enum Gender {
    Female = 0,
    Male = 1,
    // NonBinary = 2
}

export class Student implements IStudent {
    Id: string;
    FirstName: string;
    LastName: string;
    DateOfBirth?: Date;
    Gender?: Gender;
    ImageUri: string;
    Level?: ILevel;
    LevelId: string;
    Status: StudentStatus;
    ProgramIds: string[];
    PromotionHistory: StudentPromotion[];
    Weight: number;
    CustomValues: ICustomValue[];
    TrainingFacility: string;
    SpecialNeeds: boolean;
    EventRegistrations?: string[];

    constructor(
        params: IStudent = {} as IStudent) {
            
        let {            
            Id = "",
            FirstName = "",
            LastName = "",
            ImageUri = "",
            ProgramIds = [],
            Level = undefined,
            LevelId = "",            
            DateOfBirth = undefined,
            Gender = undefined,
            Status = StudentStatus.Active,
            PromotionHistory = new Array<StudentPromotion>(),
            Weight = 0,
            CustomValues = [],
            TrainingFacility = '',
            SpecialNeeds = false,
            EventRegistrations = undefined
        } = params;
            
        this.Id = Id;
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.ImageUri = ImageUri;
        this.Level = Level;
        this.LevelId = LevelId;
        this.Status = Status;
        this.ProgramIds = ProgramIds;
        this.PromotionHistory = PromotionHistory;
        this.DateOfBirth = DateOfBirth;
        this.Gender = Gender;
        this.Weight = Weight;
        this.CustomValues = CustomValues;
        this.TrainingFacility = TrainingFacility;
        this.SpecialNeeds = SpecialNeeds;
        this.EventRegistrations = EventRegistrations;
    }
}