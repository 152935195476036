import { DefaultButton, Dialog, DialogFooter, Text, PrimaryButton, SpinButton, Stack, Label, Checkbox, IconButton, Modal, IButtonStyles, FontWeights, IIconProps, getTheme, mergeStyleSets, DefaultPalette } from '@fluentui/react';
import React, { FC, useState } from 'react';
import { ILineItemCancelation, refundTransaction } from '../../../ApiService';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { useAccount, useMsal } from "@azure/msal-react";
import Loader from '../../../components/Loader';
import { Transaction } from '../../../model/Transaction';
import { GetPriceDisplayString } from '../../../Display';
import { IChartProps, StackedBarChart } from '@fluentui/react-charting';

interface IData {
    transaction: Transaction | undefined;
    relatedTransactions: Transaction[] | undefined;
    tournamentId: string;
    onDismiss: () => void;
    onRefundCompleted: (isSuccess: boolean) => void;
}

const RefundDialog: FC<IData> = ({ transaction, relatedTransactions, tournamentId, onDismiss, onRefundCompleted }) => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const refundedTransactions = relatedTransactions?.filter(t => t.Id.startsWith(`${transaction?.Id}_refund_`));
    const refundedLineItems = refundedTransactions?.map(t => t.LineItems).flat();
    
    const [refundAmount, setRefundAmount] = useState<number>(0);
    const [alreadyRefunded, ] = useState<number>(refundedTransactions?.reduce((acc, t) => acc + t.GetTotal(), 0) || 0);
    const [lineItemsToCancel, setLineItemsToCancel] = useState<ILineItemCancelation[]>([]);
    
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
        
    const [hideDialog, { toggle: toggleDialog }] = useBoolean(false);

    const onSubmitRefund = async () => {
        if (transaction === undefined) {
            return;
        }

        if (refundAmount === 0) {
            alert('Please enter a refund amount greater than $0');
            return;
        }

        setIsSubmitting(true);
        
        var result = await refundTransaction(instance,
            account!,
            tournamentId!,
            transaction.Id,
            {
                amount: refundAmount,
                description: transaction.GetDescription(),
                lineItemsToCancel: lineItemsToCancel
            });

        if (result) {
            toggleDialog();
        }
        else {
            alert('Sorry, something went wrong');
        }
        onRefundCompleted(result!);
        setIsSubmitting(false);
    }

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 550 } };
    
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps  
        [labelId, subTextId],
    );
    
    const getLoss = (): number => {
        if (transaction === undefined) {
            return 0;
        }

        //the total loss is the amount above the transaction totalless fees that is being refunded
        return refundAmount - (transaction.GetTotalLessFees() - absRefundAmount);
    }

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 500        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });
    
    const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
    };
    const [isCancelModalOpen, { setTrue: showCancelModal, setFalse: hideCancelModal }] = useBoolean(false);

    let absRefundAmount = Math.abs(alreadyRefunded);
    const refundData: IChartProps = {
        chartTitle: `Customer Paid ${GetPriceDisplayString(transaction!.GetTotal())}`,
        chartData: [
            { legend: `Net Proceeds ${GetPriceDisplayString(transaction!.GetTotalLessFees()-absRefundAmount)}`, data: transaction!.GetTotalLessFees() - absRefundAmount, color: DefaultPalette.green },
            { legend: `Payment Processor Fees ${GetPriceDisplayString(transaction!.GetTotal() - transaction!.GetTotalLessFees()- alreadyRefunded)}`, data: transaction!.GetTotal() - transaction!.GetTotalLessFees(), color: DefaultPalette.magenta },
        ],
    };
    
    if (absRefundAmount > 0) {
        refundData!.chartData!.push({ legend: `Already Refunded ${GetPriceDisplayString(absRefundAmount)}`, data: absRefundAmount, color: DefaultPalette.blackTranslucent40 });
    }

    return (        
        <div>
            {transaction !== undefined &&
                <Dialog
                    modalProps={modalProps}
                    dialogContentProps={{
                        closeButtonAriaLabel: 'Close',
                        title: "Refund Transaction"
                    }}
                    hidden={hideDialog}
                    onDismiss={() => onDismiss()}>
                    {isSubmitting ?
                        <Loader Text='Just a moment...' /> :
                        <Stack tokens={{ childrenGap: 5 }}>
                            <StackedBarChart data={refundData} ignoreFixStyle enabledLegendsWrapLines/>  
                            <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <Label>Items to cancel</Label>
                                <IconButton 
                                    styles={{root:{width:15,height:15}}} 
                                    style={{borderRadius:'50%', border:'solid', borderWidth:1, marginTop:7}} 
                                    iconProps={{iconName:'StatusCircleQuestionMark'}} 
                                    onClick={showCancelModal} />
                            </Stack>
                            {transaction.LineItems.map((li, index) =>
                                <Checkbox
                                    disabled={refundedLineItems?.find(r =>
                                        (li.EventId?.length > 0 &&
                                            r.EventId === li.EventId &&
                                            r.StudentId === li.StudentId) ||
                                        (li.CatalogItemId?.length > 0 &&
                                            r.CatalogItemId === li.CatalogItemId &&
                                            r.StudentId === li.StudentId)) !== undefined}
                                    styles={{ root: { alignSelf: 'start', paddingLeft: 10 } }}
                                    key={index}
                                    label={li.Description}
                                    onChange={(ev, checked) => {
                                    if (li.CatalogItemId?.length > 0) {
                                        if (checked) {
                                            setLineItemsToCancel([...lineItemsToCancel, { catalogItemId: li.CatalogItemId, studentId: li.StudentId }]);
                                        }
                                        else {
                                            setLineItemsToCancel(lineItemsToCancel.filter(x => (x.catalogItemId !== li.CatalogItemId && x.studentId !== li.StudentId)));
                                        }
                                    }
                                    else if (li.EventId?.length > 0) {
                                        if (checked) {
                                            setLineItemsToCancel([...lineItemsToCancel, { eventId: li.EventId, studentId: li.StudentId}]);
                                        }
                                        else {
                                            setLineItemsToCancel(lineItemsToCancel.filter(x => (x.eventId !== li.EventId && x.studentId !== li.StudentId)));
                                        }
                                    }
                                    else {
                                        return null;
                                    }
                                }} />
                            )}  
                            <br />
                            <Label>Refund amount</Label>                            
                            <SpinButton   
                                styles={{ root: { paddingLeft: 10, maxWidth:150 } }}    
                                min={0}
                                max={transaction.GetTotal() - absRefundAmount}
                                step={1}
                                defaultValue="0"
                                onChange={(ev, newValue) => setRefundAmount(parseFloat(newValue!))}
                            />
                            <br />
                                
                            {(refundAmount > (transaction.GetTotalLessFees() - absRefundAmount)) &&
                                <Text variant='medium'>
                                    <span style={{ color: 'red' }}>Note that payment processor does not refund transaction fees, this will result in a loss of {GetPriceDisplayString(getLoss())}.</span>
                                </Text>
                            }
                        </Stack>
                    }
                    <DialogFooter>
                        <PrimaryButton disabled={isSubmitting} onClick={onSubmitRefund} text="Refund" />
                        <DefaultButton disabled={isSubmitting} onClick={() => onDismiss()} text="Don't refund" />
                    </DialogFooter>
                </Dialog>
            }
            <Modal
                    isOpen={isCancelModalOpen}
                    onDismiss={hideCancelModal}
                    containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span>What are canceled items?</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close modal"
                        onClick={hideCancelModal} />
                </div>
                    <div className={contentStyles.body}>
                        <Stack tokens={{childrenGap:10}}>
                            <Text variant="medium">Items that are canceled will not be included during Athlete Call, credential/bracket generation, or as part of catalog purchase reports. Typically you should cancel items when issuing a refund, except in rare circumstances.</Text>
                        </Stack>
                    
                </div>
            </Modal>
        </div>            
    );
}

export default RefundDialog; 