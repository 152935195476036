import { DateTime } from "luxon";
import { InputType, IUserInput } from "./model/CatalogItem";
import { Student } from "./model/Student";
import { Tournament } from "./model/Tournament";

export const NormalisePhoneNumber = (input: string) => {
  return input.replace(/[^0-9+]/g, '');
}

export const CleanId = (t: string, replaceChar: string = '-'): string => {
  //replace all non-alphanumeric characters and spaces from the string
  return t.replace(/ /g, replaceChar).replace(/[^a-zA-Z0-9_-]/g, replaceChar).toLowerCase();
}

const emailValid = (val: string): boolean => /^[a-z0-9][a-z0-9-_./+]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(val);

export const ValidateEmailField = (value: string): boolean => {
  return emailValid(value.toLowerCase());
}

export const ValidateEmailFieldWithError = (value: string): string => {
  if (ValidateEmailField(value)) {
    return '';
  }

  return 'Email is invalid';
}

export const ValidateInput = (input: IUserInput, student: Student): boolean => {
  if (input.BindTo.startsWith("x:")) {
    var existingValue = student.CustomValues.find(c => c.Key === input.BindTo.substring(2));

    if (existingValue === undefined) {
      return false;
    }
    else {
      return existingValue.Value !== undefined && existingValue.Value.length > 0;
    }
  }
  
  if ((student as any)[input.BindTo] === undefined) {
    return false;
  }

  if (input.Type === InputType.Number) {
    return !isNaN(parseFloat((student as any)[input.BindTo]));
  }
  else {
    return (student as any)[input.BindTo].length > 0;
  }
}

export const GetCurrentValue = (input: IUserInput, student: Student): string | undefined => {
  if (input.BindTo.startsWith("x:")) {
    var existingValue = student.CustomValues.find(c => c.Key === input.BindTo.substring(2));

    if (existingValue === undefined) {
      return undefined;
    }
    else {
      return existingValue.Value;
    }
  }

  var value = Object.entries(student).find(k => k[0] === input.BindTo);  
  return value === undefined ? undefined : value[1].toString();
}

export const CanRegister = (tournament?: Tournament) : boolean => {
  if(tournament === undefined) {
    return false;
  }

  if(tournament.RegistrationEnds !== undefined) {
    return DateTime.utc() < DateTime.fromJSDate(tournament.RegistrationEnds);
  }
  else {
      return DateTime.utc() < DateTime.fromJSDate(tournament.StartDate);
  }
}