import { Image, Checkbox, getTheme, IScrollablePaneStyles, mergeStyleSets, ScrollablePane, ScrollbarVisibility, Stack, Text, PrimaryButton } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { Student } from '../../../model/Student';
import { Tournament } from '../../../model/Tournament';
import { TournamentRegistration } from '../../../model/TournamentRegistration';
import CheckoutForm from './CheckoutForm';
import { Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ICouponResult, setupTournamentPayment } from '../../../ApiService';
import { useAccount, useMsal } from '@azure/msal-react';
import Loader from '../../../components/Loader';
import { ILineItem } from '../../../model/Transaction';
import EventInputCollector from './EventInputCollector';
import { useNavigate } from 'react-router-dom';
import ApplyCoupon from './ApplyCoupon';
import ErrorPage from '../../ErrorPage';

export interface IData {
    stripePromise: Promise<Stripe | null>;
    students: Student[];
    tournament: Tournament;
    pendingRegistration: TournamentRegistration;
}

enum RegistrationState {
    CheckForNeededInputs = 0,
    SelectedEvents = 1,
    EnterPaymentDetails = 2,
    AcceptTerms = 3,
    ConfirmFreeRegistration = 4,
    HasError = 5
}

enum PurchaseableType {
    Event = 0,
    CatalogItem = 1
}

interface IAdditionalTermsAcceptance {
    hasAccepted: boolean;
    purchaseableId: string;
    purchaseableType: PurchaseableType;
    value: string;
}

const RegisterTournament : FC<IData> = ({students, pendingRegistration, tournament, stripePromise}) => {    
    const [status, setStatus] = useState<RegistrationState>(RegistrationState.CheckForNeededInputs);
    const [paymentSecret, setPaymentSecret] = useState<string>();
    const [transactionId, setTransactionId] = useState<string>();
    const [lineItems, setLineItems] = useState<ILineItem[]>();
    const [total, setTotal] = useState<number>(0);
    const [tax, setTax] = useState<number>(0);
    const [convenienceFee, setConvenienceFee] = useState<number>(0);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean>(false);

    const buildTerms = (): IAdditionalTermsAcceptance[] => {        
        var catItems = tournament.Catalog.filter(c =>
            c.AdditionalTerms?.length > 0 &&
            pendingRegistration.Catalog.find(p => p.CatalogItemId === c.Id) !== undefined);
        var eventItems = tournament.Events.filter(e =>
            e.AdditionalTerms?.length > 0 &&
            pendingRegistration.Events.find(p => p.CurriculumId === e.EventId) !== undefined);
        
        var toReturn: IAdditionalTermsAcceptance[] = [];

        for (let c of catItems) {
            for (let t of c.AdditionalTerms) {
                if (toReturn.find(r => r.value === t) === undefined) {
                    toReturn.push({
                        hasAccepted: false,
                        purchaseableId: c.Id,
                        purchaseableType: PurchaseableType.CatalogItem,
                        value: t
                    });
                }
            }
        }

        for (let e of eventItems) {
            for (let t2 of e.AdditionalTerms) {
                if (toReturn.find(r => r.value === t2) === undefined) {
                    toReturn.push({
                        hasAccepted: false,
                        purchaseableId: e.EventId,
                        purchaseableType: PurchaseableType.Event,
                        value: t2
                    });
                }
            }
        }

        return toReturn;
    }
    
    const [acceptedAdditionalTerms, setAcceptedAdditionalTerms] = useState<IAdditionalTermsAcceptance[]>(buildTerms());
    
    const [isFree, setIsFree] = useState<boolean>(false);
    const [inputsCount, setInputsCount] = useState<number>();
    
    const navigate = useNavigate();

    const { instance, accounts} = useMsal();
    const account = useAccount(accounts[0] || {});    

    useEffect(()=> {
        const fetchPaymentSecretAsync = async () => {
            var response = await setupTournamentPayment(instance, account!, pendingRegistration);

            if(response === undefined) {
                setStatus(RegistrationState.HasError);
                return;
            }
            
            setIsFree(!response.NeedsPayment);
            setPaymentSecret(response.ClientSecret);

            let total = 0;
            let tax = 0;

            for(let l of response.LineItems) {
                let taxAmount = 0;

                if(l.Tax !== undefined) {
                    taxAmount = l.Tax;
                }

                tax += taxAmount;
                total += l.Price + taxAmount;
            }

            setTax(tax);
            setConvenienceFee(response.ConvenienceFee);
            setTotal(total);
            setLineItems(response.LineItems);
            setTransactionId(response.TransactionId);
        }
        if(account) {
            setInputsCount(tournament.Events.filter(e=> pendingRegistration.Events.find(p=>p.CurriculumId === e.EventId) !== undefined && e.Inputs.length > 0).length);
            fetchPaymentSecretAsync();
        }        
    },[account, instance, pendingRegistration, tournament]);

    useEffect(()=> {
        if(inputsCount === 0) {
            goNext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inputsCount]);

    const goNext = () => {
        switch(status) {
            case RegistrationState.CheckForNeededInputs: {
                setStatus(RegistrationState.SelectedEvents);
                break;
            }
            case RegistrationState.SelectedEvents:{
                setStatus(RegistrationState.AcceptTerms);
                break;
            }
            case RegistrationState.AcceptTerms: {
                
                if(!isFree) {
                    setStatus(RegistrationState.EnterPaymentDetails);
                }
                else {
                    setStatus(RegistrationState.ConfirmFreeRegistration);
                }
                break;
            }
            case RegistrationState.EnterPaymentDetails: {
                
                break;
            }
        }
    } 
       
    const theme = getTheme();
    
    const scrollablePaneClassNames = mergeStyleSets({        
        pane: {
          border: '1px solid ' + theme.palette.neutralLight,
        },
        sticky: {
          color: theme.palette.neutralDark,
          padding: '5px 10px 5px 10px',
          fontSize: '13px',
          borderTop: '1px solid ' + theme.palette.black,
          borderBottom: '1px solid ' + theme.palette.black,
        }
      });

    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: scrollablePaneClassNames.pane };

    const [discount, setDiscount] = useState<number>(0);

    const onCouponApplied = (result: ICouponResult, coupon: string) => {        
        if (result.AmountOwing <= 0) {
            setIsFree(true);
            setDiscount(result.Discount);
            setConvenienceFee(result.ConvenienceFee);
            return;
        }
        
        setConvenienceFee(result.ConvenienceFee);
        setDiscount(result.Discount);
    }

    const updateAcceptedState = (checked: boolean, purchaseableId: string, purchaseableType: PurchaseableType, value: string) => {        
        var idx = acceptedAdditionalTerms.findIndex(t =>
            t.purchaseableId === purchaseableId &&
            t.purchaseableType === purchaseableType && 
            t.value === value);
        
        acceptedAdditionalTerms[idx].hasAccepted = checked;
        setAcceptedAdditionalTerms(acceptedAdditionalTerms => [...acceptedAdditionalTerms]);
    }

    return (
        <>
            <Stack tokens={{childrenGap:10}} verticalAlign='stretch' verticalFill>
                <br />
                {status === RegistrationState.HasError ?
                    <ErrorPage error={{error: 'An unknown error has occurred'}} isSmall /> : null}

                {status === RegistrationState.CheckForNeededInputs ? 
                    paymentSecret === undefined ? 
                    <Loader Text='Just a moment...' /> : 
                    <Stack>
                        <Stack.Item align='start'>
                            <Text variant='medium'>{inputsCount === 1 ? "1 event requires" : `${inputsCount} events require`} additional details</Text>
                            {/* <Text variant='medium'>Additional details required</Text> */}
                        </Stack.Item>
                        <br /><br />
                        <EventInputCollector onComplete={()=>goNext()} pendingRegistration={pendingRegistration} students={students} tournament={tournament} />
                        <br /><br />                        
                    </Stack> : null}

                {status === RegistrationState.SelectedEvents ? 
                    paymentSecret === undefined ? 
                    <Loader Text='Just a moment...' /> : 
                    <Stack>
                        <Stack.Item align='start'>
                            <Text variant='large'>Confirm Purchase</Text>
                        </Stack.Item>
                        <Stack.Item align='start'>
                            <Text variant='small'>Step 1 of 3</Text>
                        </Stack.Item>
                        <br /><br />
                        <table>
                            <tbody>
                                {lineItems?.map((l,idx) => <tr key={idx}>
                                        <td><Image style={{height:30}} src={tournament.Events.find(t=> t.EventId === l.EventId)?.ImageUri} /></td>
                                        <td>
                                            <Stack>
                                                <Text variant='medium'>{l.CatalogItemId !== null && l.CatalogItemId.length > 0 ? tournament.Catalog.find(c=>c.Id === l.CatalogItemId)?.Name : tournament.Events.find(t=> t.EventId === l.EventId)?.Title}</Text>
                                                <Text variant='small'>{l.Description}</Text>
                                            </Stack>
                                        </td>
                                        <td style={{verticalAlign:'top', textAlign:'right'}}><Text>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(l.Price)}</Text></td>
                                    </tr>
                                    )
                                }
                                <tr><td colSpan={3}><hr/></td></tr>
                                {discount > 0 ? <tr><td colSpan={2} align='right' style={{color:'green'}}>Discount:</td><td align='right'><Text style={{color:'green'}}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(discount*-1)}</Text></td></tr>: null}
                                <tr><td colSpan={2} align='right'><strong>Tax:</strong></td><td align='right'><Text>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(tax)}</Text></td></tr>
                                {convenienceFee > 0 ? <tr><td colSpan={2} align='right'><strong>{tournament.ConvenienceFeeDescriptor}:</strong></td><td align='right'><Text>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(convenienceFee)}</Text></td></tr> : null}
                                <tr><td colSpan={2} align='right'><strong>Total:</strong></td><td align='right'><Text>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total - discount + convenienceFee)}</Text></td></tr>
                            </tbody>
                        </table>
                        <br />
                        {transactionId === undefined ? null : discount > 0 ? null : <ApplyCoupon transactionId={transactionId} onCouponApplied={onCouponApplied} />}
                        <br /><br />                        
                    </Stack> : null}

                {status === RegistrationState.AcceptTerms ? 
                <Stack tokens={{childrenGap:10}}>
                    <Stack.Item align='start'>
                        <Text variant='large'>Accept Terms</Text>
                        <br />                    
                        <Text variant='small'>Step 2 of 3</Text>
                        </Stack.Item>
                        {acceptedAdditionalTerms.length > 0 ? <><br /><Text>By checking the following boxes, I indicate that I accept:</Text></> : null}

                        {acceptedAdditionalTerms.map((additionalTerm, idx) =>
                            <Checkbox
                                key={idx}
                                onChange={(e, checked) => updateAcceptedState(checked!, additionalTerm.purchaseableId, additionalTerm.purchaseableType, additionalTerm.value)}
                                label={additionalTerm.value} />
                        )}
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} style={{minHeight:'450px', position:'relative'}} styles={scrollablePaneStyles}>
                        <div style={{padding: '10px'}}>
                            <p dangerouslySetInnerHTML={{__html: tournament.Terms}} />
                        </div>
                    </ScrollablePane>
                    <Stack.Item align='end'>
                        <a href={`/tournament/${tournament.Id}/terms`} target='_blank' rel='noreferrer'>View terms (opens in new tab)</a>
                        <br />
                    </Stack.Item>
                    <Checkbox onChange={(e, checked) => setHasAcceptedTerms(checked!)} label='Accept terms' />                                           
                </Stack> : null}

                {status === RegistrationState.EnterPaymentDetails ? 
                <Stack>
                    <Stack.Item align='start'>
                        <Text variant='large'>Enter Payment Details</Text>
                    </Stack.Item>
                    <Stack.Item align='start'>
                        <Text variant='small'>Step 3 of 3</Text>
                    </Stack.Item>
                    <Elements 
                        options={{
                            clientSecret:paymentSecret, 
                            appearance:{theme:'stripe'}}
                        } 
                        stripe={stripePromise}>
                            <CheckoutForm 
                                returnUri={`${document.location.origin}/tournament/registration/complete/${tournament.Id}`} />
                    </Elements>
                </Stack> : null }

                {status === RegistrationState.ConfirmFreeRegistration ? 
                <Stack>
                    <Stack.Item align='start'>
                        <Text variant='large'>Complete Registration</Text>
                    </Stack.Item>
                    <Stack.Item align='start'>
                        <Text variant='small'>Step 3 of 3</Text>
                    </Stack.Item>
                    <Stack tokens={{childrenGap:10}}>
                        <br />                        
                        <Text>No payment required.</Text>
                        <br />
                        <PrimaryButton onClick={()=>navigate(`/event/registration/complete/${tournament.Id}/?payment_intent=${transactionId}`)}>Submit</PrimaryButton>
                    </Stack>
                </Stack> : null }

                <Stack.Item align='end'>
                    {status === RegistrationState.SelectedEvents ?
                        <PrimaryButton disabled={paymentSecret === undefined} onClick={()=> goNext()}>Next</PrimaryButton> :
                            status === RegistrationState.AcceptTerms ?
                            <PrimaryButton disabled={!hasAcceptedTerms ||
                                (acceptedAdditionalTerms.length > 0 ? !acceptedAdditionalTerms.every(a => a.hasAccepted) : false)} onClick={() => goNext()}>Next</PrimaryButton> :
                                null
                    }
                </Stack.Item>
            </Stack>
        </>
    );
}

export default RegisterTournament;