import { DateTime } from "luxon";
import { InputType } from "./model/CatalogItem";
import { Gender } from "./model/Student";
import { IPricingStrategy, Tournament, TournamentLayout, TournamentType } from "./model/Tournament";
import { TournamentEvent } from "./model/TournamentEvent";

export const GetUserInputTypeString = (inputType: InputType): string => {
    return inputType === InputType.String ? "Text" : "Number";
}

export const GetPriceDisplayString = (initialPrice: number, subsequentPrice?: number, showFreeInsteadOfZero: boolean = false) : string => {
    if (initialPrice === subsequentPrice ||
        subsequentPrice === undefined) {
        if (initialPrice === 0) {
             return showFreeInsteadOfZero ? "Free" : `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0)}`;
        }
        
        return `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(initialPrice)}`;
    }

    var initialPriceString = initialPrice === 0 ?
        showFreeInsteadOfZero ? "Free" : 0
        : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(initialPrice);
    var subsequentPriceString = subsequentPrice === 0 ? 
        showFreeInsteadOfZero ? "Free" : 0
        : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(subsequentPrice);

    return `${initialPriceString} or ${subsequentPriceString} if additional event`;
}

export const GetPhoneDisplayString = (p: string) => {
    if (p === null || p === undefined) {
        return '';
    }

    const phoneNumber = p.replace(/[^\d]/g, ''); // Remove non-digit characters
    const countryCode = phoneNumber.slice(0, 1); // Extract the country code
    const areaCode = phoneNumber.slice(1, 4); // Extract the area code
    const firstPart = phoneNumber.slice(4, 7); // Extract the first part of the phone number
    const secondPart = phoneNumber.slice(7, 11); // Extract the second part of the phone number

    return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
}

export const GetTournamentLocalTime = (date: Date, timezone: string): string => {
    return DateTime.fromJSDate(date, { zone: timezone }).toLocaleString(DateTime.DATETIME_MED);
}

export const GetTournamentStartAndEndDates = (startDate: Date, endDate: Date, timezone: string): string => {    
    
    var startInLocalTime = DateTime.fromJSDate(startDate, { zone: timezone });
    var endInLocalTime = DateTime.fromJSDate(endDate, { zone: timezone });
    
    if (startInLocalTime.day === endInLocalTime.day &&
        startInLocalTime.month === endInLocalTime.month &&
        startInLocalTime.year === endInLocalTime.year) {
        return `${startInLocalTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} (${startInLocalTime.toLocaleString(DateTime.TIME_SIMPLE)} - ${endInLocalTime.toLocaleString(DateTime.TIME_SIMPLE)})`;
    }

    return `${startInLocalTime.toLocaleString(DateTime.DATE_MED)} - ${endInLocalTime.toLocaleString(DateTime.DATE_MED)}`;
}

export const GetPricingLabel = (tournament: Tournament, e: TournamentEvent): string => {
    var pricing = tournament.Pricing.find(p => p.CurriculumId === e.EventId);

    if (pricing === undefined) {
        return 'invalid price';
    }

    //older style of price definition
    if (pricing.PricingStrategyId === undefined || pricing.PricingStrategyId === null) {
        return `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(pricing.Price + pricing.AdditionalFee)} (flat rate)`;            
    }

    //newer style of price definition
    var strategy = tournament.PricingStrategies.find(s => s.Id === pricing?.PricingStrategyId);
    
    if (strategy === undefined) {
        return 'invalid price';
    }

    return GetPricingLabelForStrategy(strategy, pricing.AdditionalFee);
}

export const GetPricingLabelForStrategy = (p: IPricingStrategy, additionalFee: number) => {
    if (p.InitialPrice === p.SubsequentPrice) {
        return `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(p.InitialPrice + additionalFee)}`;
    }

    return `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(p.InitialPrice + additionalFee)} or ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(p.SubsequentPrice + additionalFee)} if additional`
}

export const GetGenderString = (gender: Gender) : string => {
    switch(gender) {
        case Gender.Female: {
                return "Female";
            }
        case Gender.Male: {
                return "Male"
            }
        // case Gender.NonBinary: {
        //         return "NonBinary"
        //     }
    }
}

export const GetTournamentTypeString = (tournamentType: TournamentType) : string => {
    switch(tournamentType) {
        case TournamentType.Hybrid:
            return "Hybrid";
        case TournamentType.InPerson:
            return "In Person";
        case TournamentType.Online:
            return "Online";
    }
}

export const GetTournamentLayoutString = (tournamentLayout: TournamentLayout) : string => {
    switch(tournamentLayout) {
        case TournamentLayout.Compact:
            return "Compact";
        case TournamentLayout.Default:
            return "Default";        
    }
}

export const GetIssuerString = (issuer: string) : string => {
    if(issuer === "google.com") {
      return "Google";
    }

    if(issuer === "https://appleid.apple.com") {
      return "Apple";
    }

    if(issuer === "live.com") {
      return "Microsoft"
    }

    return issuer;
  }