import { Dropdown, IDropdownOption, Label, ProgressIndicator, Stack, Text, TextField } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { InputType, IUserInput } from "../../../model/CatalogItem";
import { Student } from "../../../model/Student";
import { GetCurrentValue } from '../../../Validation';
import { validateInput } from '../../../ApiService';
import { useAccount, useMsal } from '@azure/msal-react';
import { IValidationResult } from '../../../model/ValidationResult';

export enum DisplayMode {
    StudentNameHorizontal,
    LabelHorizontal,
    StudentNameVertical,
    LabelVertical
}

export interface IData {
    input: IUserInput;
    student: Student;
    displayMode?: DisplayMode;
    required?: boolean;
    tournamentId: string;
    onValidationChanged: (isValid: boolean, errorMessage?: string) => void;
}

const StudentInput : FC<IData> = ({input, student, tournamentId, required, displayMode = DisplayMode.StudentNameHorizontal, onValidationChanged}) => {
    const [initialErrorMessage, setInitialErrorMessage] = useState<string | undefined>(undefined);
    const [oldValue, setOldValue] = useState<string | undefined>(GetCurrentValue(input, student));
    const [isValidating, setIsValidating] = useState<boolean>(false);    
    const [validationResult, setValidationResult] = useState<IValidationResult>({ IsValid: false });
    const { instance, accounts } = useMsal();    
    const account = useAccount(accounts[0] || {}); 

    useEffect(() => {
        if(oldValue === undefined || oldValue.length === 0) {
            return;
        }

        onValidate(input, oldValue).then(() => setInitialErrorMessage(validationResult.ErrorMessage));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onOptionDropdownChanged = (input: IUserInput, newValue: string) => {
        if(input.BindTo.startsWith("x:")) {
            var existingValue = student.CustomValues.find(c=>c.Key === input.BindTo.substring(2));
            
            if(existingValue === undefined) {
                student.CustomValues.push({Key: input.BindTo.substring(2), Value: newValue === undefined ? '' : newValue });
            }
            else {
                existingValue.Value = newValue === undefined ? '' : newValue;
            }
        }
        else {
            if(input.Type === InputType.Number) {
                (student as any)[input.BindTo] = newValue === undefined ? 0 : parseFloat(newValue);
            }
            else {
                (student as any)[input.BindTo] = newValue === undefined ? '' : newValue;
            }
        }

        //just validate that it's not empty
        onValidationChanged(newValue !== undefined && newValue.length > 0);
    }

    const onInputChanged = (validationResult: IValidationResult, newValue?: string) => {    
        if(input.BindTo.startsWith("x:")) {
            var existingValue = student.CustomValues.find(c=>c.Key === input.BindTo.substring(2));
            
            if(existingValue === undefined) {
                student.CustomValues.push({Key: input.BindTo.substring(2), Value: newValue === undefined ? '' : newValue });
            }
            else {
                existingValue.Value = newValue === undefined ? '' : newValue;
            }
        }
        else {
            if(input.Type === InputType.Number) {
                (student as any)[input.BindTo] = newValue === undefined ? 0 : parseFloat(newValue);
            }
            else {
                (student as any)[input.BindTo] = newValue === undefined ? '' : newValue;
            }
        }

        setValidationResult(validationResult);

        //just validate that it's not empty
        onValidationChanged(newValue !== undefined && newValue.length > 0 && validationResult.IsValid, validationResult.ErrorMessage);
    }

    const onValidate = async (input: IUserInput, value: string): Promise<string | undefined> => {   
        setOldValue(value);
        setInitialErrorMessage(undefined);

        if (value.length === 0) {
            onInputChanged({ IsValid: input.IsRequired ? false : true }, value);
            return '';
        }   

        if (input.DataValidator === undefined || input.DataValidator === null) {
            onInputChanged({ IsValid: true }, value);
            return '';
        }

        setIsValidating(true);

        let result = await validateInput(instance, account!, tournamentId, input.BindTo, value);            

        if (result === undefined || result === null) {
            let errorMessage = 'Unspecified error.';
            onInputChanged({ IsValid: true, ErrorMessage: errorMessage }, value);
            setIsValidating(false);
            return errorMessage;
        }

        onInputChanged(result, value);
        setIsValidating(false);

        if (!result.IsValid) {
            return result.ErrorMessage === undefined ? 'Unspecified error.' : result.ErrorMessage;
        }        

        return undefined;
    }

    return (
        <>
            {input.Options === null || input.Options.length === 0 ?
                displayMode === DisplayMode.StudentNameHorizontal || displayMode === DisplayMode.LabelHorizontal ?
                    <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign='space-between'>
                        <Stack.Item align='start'>
                            <Text variant='mediumPlus'>{displayMode === DisplayMode.StudentNameHorizontal ? student.FirstName : input.Label}:</Text>
                        </Stack.Item>
                        <Stack.Item align='start'>
                            <TextField
                                disabled={isValidating}
                                defaultValue={GetCurrentValue(input, student)}
                                style={{ minWidth: 150, fontSize: 16 }}                                
                                validateOnFocusOut={input.DataValidator !== undefined && input.DataValidator !== null}
                                errorMessage={initialErrorMessage}
                                onGetErrorMessage={async (value) => value! === oldValue ?
                                    validationResult.ErrorMessage === undefined ? '' :
                                        validationResult.ErrorMessage! : await onValidate(input, value) || ''}
                                inputMode={input.Type === InputType.Number ? 'numeric' : 'text'} />
                            {isValidating ? <ProgressIndicator /> : null}
                        </Stack.Item>
                    </Stack> :
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Label>{displayMode === DisplayMode.StudentNameVertical ? student.FirstName : input.Label}</Label>
                        <TextField
                            disabled={isValidating}
                            defaultValue={GetCurrentValue(input, student)}
                            style={{ minWidth: 150, fontSize: 16 }}
                            validateOnFocusOut={input.DataValidator !== undefined && input.DataValidator !== null}
                            errorMessage={initialErrorMessage}
                            onGetErrorMessage={async (value) => value! === oldValue ?
                                validationResult.ErrorMessage === undefined ? '' :
                                    validationResult.ErrorMessage! : await onValidate(input, value) || ''}
                            inputMode={input.Type === InputType.Number ? 'numeric' : 'text'} />
                        {isValidating ? <ProgressIndicator /> : null}
                    </Stack>
                :
                displayMode === DisplayMode.StudentNameHorizontal || displayMode === DisplayMode.LabelHorizontal ?
                    <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign='end'>
                        <Stack.Item align='start'>
                            <Text variant='mediumPlus'>{displayMode === DisplayMode.StudentNameHorizontal ? student.FirstName : input.Label}:</Text>
                        </Stack.Item>
                        <Stack.Item align='start'>
                            <Dropdown
                                required={required}
                                defaultSelectedKey={input.Options.find(o => o.Label === GetCurrentValue(input, student))?.Id}
                                onChange={(e, o) => onOptionDropdownChanged(input, o!.text)}
                                style={{ minWidth: 180, fontSize: 16 }}
                                options={input.Options.map((o) => ({ key: o.Id, text: o.Label } as IDropdownOption))} />
                        </Stack.Item>
                    </Stack>
                    :
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Label>{displayMode === DisplayMode.StudentNameVertical ? student.FirstName : input.Label}</Label>
                        <Dropdown
                            required={required}
                            defaultSelectedKey={input.Options.find(o => o.Label === GetCurrentValue(input, student))?.Id}
                            onChange={(e, o) => onOptionDropdownChanged(input, o!.text)}
                            style={{ minWidth: 180, fontSize: 16 }}
                            options={input.Options.map((o) => ({ key: o.Id, text: o.Label } as IDropdownOption))} />
                    </Stack>
            }
        </> 
    )
}

export default StudentInput;