import React, { FC, useEffect, useState } from 'react';
import { Selection, IColumn, SelectionMode, Stack, CheckboxVisibility, SearchBox, Checkbox, Text, ICommandBarItemProps, CommandBar, Modal, FontWeights, mergeStyleSets, getTheme, DefaultButton, Dialog, DialogFooter, PrimaryButton, DialogType } from '@fluentui/react';
import { useMsal, useAccount } from "@azure/msal-react";
import { downloadTournamentTransactions, getAllTransactions, getTransactionsForTournament, resendReceipt } from '../../../ApiService';
import Loader from '../../../components/Loader';
import List, { IDocument } from '../../../components/List';
import { Transaction, TransactionStatus, TransactionType } from '../../../model/Transaction';
import { TransactionViewModel } from '../../../model/TransactionViewModel';
import { UserProfile } from '../../../model/UserProfile';
import { useBoolean } from '@fluentui/react-hooks';
import FileDownloader from '../../../components/FileDownloader';
import RefundDialog from './RefundDialog';

interface ITableRow extends IDocument {
    key: string;
    id: string;
    transaction: Transaction;
    category: string;
    description: string;
    grossAmount: number;
    netAmount: number;
    user: string;
    date: Date;
    method: string;
    type: TransactionType;
    status: TransactionStatus;
}


interface IData {
  tournamentId?: string;
}

const TransactionManagement : FC<IData> = ({tournamentId}) => {
    const { instance, accounts } = useMsal();    
    const account = useAccount(accounts[0] || {});
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [items, setItems] = useState<ITableRow[]>();
    const [allItems, setAllItems] = useState<ITableRow[]>();
    const [allTransactions, setAllTransactions] = useState<TransactionViewModel>();
    const [isTableLocked, ] = useState<boolean>(false);
    const [selection, setSelection] = useState<Selection>(new Selection());
    const [hidePendingTransactions, setHidePendingTransactions] = useState<boolean>(true);
    const [incompleteTransactions, setIncompleteTransactions] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<ITableRow | undefined>();

    const [columns, ] = useState<IColumn[]>([     
      // {
      //   key: 'column2',
      //   name: 'Category',
      //   fieldName: 'category',
      //   minWidth: 50,
      //   maxWidth: 60,
      //   isRowHeader: true,
      //   isResizable: true,
      //   sortAscendingAriaLabel: 'Sorted A to Z',
      //   sortDescendingAriaLabel: 'Sorted Z to A',
      //   data: 'string',
      //   isPadded: true
      // },
      {
          key: 'column3',
          name: 'Description',
          fieldName: 'description',
          minWidth: 40,
          maxWidth: 400,
          isRowHeader: true,
          isResizable: true,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          data: 'string',
          isPadded: true,
          onRender: (item: ITableRow) => {          
            return <span style={{color:item.status === TransactionStatus.Success && item.type === TransactionType.Charge ? 'initial' : 'red'}}>{item.description}</span>
          }
      },
      {
        key: 'column4a',
        name: 'Gross Amount',
        fieldName: 'grossAmount',
        minWidth: 60,
        maxWidth: 85,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'number',
        isPadded: true,
        onRender: (item: ITableRow) => {          
          return <span style={{color:item.status === TransactionStatus.Success && item.type === TransactionType.Charge ? 'initial' : 'red'}}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.grossAmount)}</span>
        }
      },
      {
        key: 'column4b',
        name: 'Net Amount',
        fieldName: 'netAmount',
        minWidth: 60,
        maxWidth: 85,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'number',
        isPadded: true, 
        onRender: (item: ITableRow) => {
          return <span style={{color:item.status === TransactionStatus.Success && item.type === TransactionType.Charge ? 'initial' : 'red'}}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.netAmount)}</span>
        }
      },
      {
        key: 'column5',
        name: 'Purchased by',
        fieldName: 'user',
        minWidth: 50,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'number',
        isPadded: true,
        onRender: (item: ITableRow) => {          
          return <span style={{color:item.status === TransactionStatus.Success && item.type === TransactionType.Charge ? 'initial' : 'red'}}>{item.user}</span>
        }
      },          
      {
        key: 'column6',
        name: 'Date',
        fieldName: 'date',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'date',   
        isPadded: true,
        onRender: (item: ITableRow) => {          
          return <span style={{color:item.status === TransactionStatus.Success && item.type === TransactionType.Charge ? 'initial' : 'red'}}>{item.date.toLocaleString()}</span>;
        }
      },
      {
        key: 'column7',
        name: 'Method',
        fieldName: 'method',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'string',   
        isPadded: true,
        onRender: (item: ITableRow) => {          
          return <span style={{color:item.status === TransactionStatus.Success && item.type === TransactionType.Charge ? 'initial' : 'red'}}>{item.method}</span>;
        }
      },
      {
        key: 'column8',
        name: 'Type',
        fieldName: 'type',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'date',   
        isPadded: true,
        onRender:(item: ITableRow) => {
          return <span style={{color:item.status === TransactionStatus.Success && item.type === TransactionType.Charge ? 'initial' : 'red'}}>{getTypeString(item.transaction)}</span>
        }            
      },
      {
        key: 'column9',
        name: 'Status',
        fieldName: 'status',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'date',   
        isPadded: true,
        onRender:(item: ITableRow) => {
          return <span style={{color:item.status === TransactionStatus.Success && item.type === TransactionType.Charge ? 'initial' : 'red'}}>{getStatusString(item.transaction)}</span>
        }            
      }
    ]);
    
    const commandBarItems : ICommandBarItemProps [] = [          
      {
        key:'transactions',
        text: 'Download all',
        iconProps: {iconName:'DownloadDocument'},
        onClick: () => {showDownloadModal()}
      }
    ];

    const [errorCode, setErrorCode] = useState<number>();

    useEffect(()=> {
      if(errorCode === undefined) {
        return;
      }

      throw new Error("The server returned status code: " + errorCode);
    },[errorCode]);

    useEffect(()=> {
        const fetchAsync = async () => {
          var allTransactions : TransactionViewModel | number;

          if(tournamentId !== undefined) {
            allTransactions = await getTransactionsForTournament(instance, account!, tournamentId);
          }
          else {
            allTransactions = await getAllTransactions(instance, account!);
          }

          if(typeof allTransactions === 'number') {
            setErrorCode(allTransactions);
            return;
          }
          
            setAllTransactions(allTransactions);
            setIsLoaded(true);
        }

        if(account) {
            fetchAsync();
        }
    },[instance, account, tournamentId]);

    useEffect(()=> {
        if(allTransactions === undefined) {
          return;
        }

        var transactions = allTransactions.Transactions;

        if(hidePendingTransactions) {          
          transactions = allTransactions.Transactions.filter(t=>t.Status !== TransactionStatus.Pending); 
        }

        var tableRows = buildTableRows(transactions, allTransactions.Users);
        setItems(tableRows);
        setAllItems(tableRows);
        setIncompleteTransactions(allTransactions.Transactions.filter(t=>t.Status === TransactionStatus.Pending).length);
        // eslint-disable-next-line react-hooks/exhaustive-deps     
      },[allTransactions]);

      useEffect(()=> {
        if (allTransactions === undefined) {
          return;
        }
        var transactions = allTransactions.Transactions;

        if(hidePendingTransactions) {          
          transactions = allTransactions.Transactions.filter(t=>t.Status !== TransactionStatus.Pending); 
        }
        
        var tableRows = buildTableRows(transactions, allTransactions.Users);                
        setItems(tableRows);
        // eslint-disable-next-line react-hooks/exhaustive-deps   
      }, [hidePendingTransactions]);

    const doSearch = (n: string | undefined) => {
      
        if(n === undefined || n.length === 0) {
          //rebuild the default list
          var defaultList = buildTableRows(allTransactions!.Transactions, allTransactions!.Users);
          setItems(defaultList);
          return;
        }
  
        var filtered = allItems?.filter(i => 
            i.id.toLowerCase().indexOf(n.toLowerCase()) !== -1 ||
            i.description.toLowerCase().indexOf(n.toLowerCase()) !== -1 || 
            i.user.toLowerCase().indexOf(n.toLowerCase()) !== -1);

        setItems([...filtered!]);
      }

      const getTypeString = (transaction: Transaction) => {
        switch(transaction.Type) {
          case TransactionType.Charge: {
            return `Charge`;
          }
          case TransactionType.Refund: {
            return "Refund";
          }
          case TransactionType.ChargeBack: {
            return "Chargeback";
          }
        }
      }

      const getStatusString = (transaction: Transaction) => {
        switch(transaction.Status) {
          case TransactionStatus.Failure: {
            return `Failed: ${transaction.ErrorDescription}`;
          }
          case TransactionStatus.Success: {
            return "Success";
          }
          case TransactionStatus.Pending: {
            return "Pending";
          }
        }
      }

      const buildTableRows = (transactions: Transaction[], users: UserProfile[]) => {
        var tableRows = new Array<ITableRow>();
            
        for(let transaction of transactions) {
            let title = `${transaction.GetDescription()}`;
            
            let t = transaction;
            
            tableRows.push({
                key: t.Id,
                transaction: t,
                grossAmount: t.GetTotal(),
                netAmount: t.GetTotalLessFees(),
                date: new Date(transaction.Date),                                    
                description: t.GetDescription(),
                method: t.CardBrand === null ? t.Method : `${t.CardBrand} ${t.LastFour}`,
                category: t.Category,
                id: t.Id,                
                status: t.Status,   
                type: t.Type,               
                user: users.find(u => u.Id === t.UserId)!.DisplayName,
                getTitle: ()=> title
            });
        }
        
        return tableRows;
      }

  const onActiveItemChanged = (item?: ITableRow, index?: number) => {
    setSelectedItem(item);
  }

    const [isDownloadModalOpen, { setTrue: showDownloadModal, setFalse: hideDownloadModal }] = useBoolean(false);

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });

  const [showRefundDialog, setShowRefundDialog] = useState<boolean>(false);

    const nearCommandBarItems : ICommandBarItemProps [] = [
      {
        key:'refund',
        text: 'Refund',
        iconProps: {iconName:'ReceiptReply'},
        disabled: selectedItem === undefined ||
          selectedItem.transaction.Status !== TransactionStatus.Success ||
          selectedItem.transaction.Type === TransactionType.Refund,
        onClick: () => setShowRefundDialog(true)
      },
      {
        key:'resend',
        text: 'Resend Receipt',
        iconProps: {iconName:'Mail'},
        disabled: selectedItem === undefined || selectedItem.transaction.GetTotal() === 0,
        onClick: () => toggleResendReceiptDialog()
      }
  ];

  const onRefundCompleted = (success: boolean) => {
    setShowRefundDialog(false);
    
    if (!success) {
      alert("Sorry, something went wrong. Please refresh the page and try again.")
      return;
    }

    items!.find(i => i.key === selection.getSelection()[0].key!)!.transaction.Type = TransactionType.Refund;
    setItems(items);
  }
  
  const [hideResendReceiptDialog, { toggle: toggleResendReceiptDialog }] = useBoolean(true);

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Resend Receipt',
    closeButtonAriaLabel: 'Close',
    subText: 'Are you sure you want to resend this receipt?',
  };

  const requestReceiptResend = async () => {
    if (account === null ||
      tournamentId === undefined ||
      selectedItem === undefined) {
      return;
    }

    var success = await resendReceipt(instance, account, tournamentId, selectedItem.transaction.Id);
    
    if (!success) {
      alert("Sorry, something went wrong. Please try again.");
      return;
    }

    toggleResendReceiptDialog();
  }
  
  return (
    <>
      {isLoaded ?
        items !== undefined && allTransactions !== undefined && allTransactions.Transactions.length > 0 ?
          <Stack tokens={{ childrenGap: 10 }}>
            <br />
            <Stack.Item align='end'>
              <SearchBox placeholder="Find transaction" onChange={(e, n) => doSearch(n)} />
            </Stack.Item>
            <Stack.Item>
              {incompleteTransactions > 0 ?
                <Stack horizontalAlign='end'>
                  <Checkbox label='Hide pending' onChange={(e, v) => setHidePendingTransactions(v!)} checked={hidePendingTransactions} />
                  <Stack.Item>
                    <Text variant='small'>({incompleteTransactions} pending transactions)</Text>
                  </Stack.Item>
                </Stack>
                : null
              }
            </Stack.Item>
            {tournamentId !== undefined ?
              <CommandBar
                items={nearCommandBarItems}
                farItems={commandBarItems} />
              : null}
            <List
              columns={columns}
              isLocked={isTableLocked}
              selectionMode={SelectionMode.single}
              enableSort
              checkboxVisibility={CheckboxVisibility.hidden}
              items={items}
              onActiveItemChanged={onActiveItemChanged}
              onSelectionChanged={(s) => setSelection(s)} />
          </Stack>
          : <div style={{ paddingTop: 20, paddingLeft: 10 }}><Text>No transactions yet.</Text></div>
        :
        <Loader Text='Just a moment...' />
      }
      <Modal
        isOpen={isDownloadModalOpen}
        onDismiss={hideDownloadModal}
        containerClassName={contentStyles.container}>
        <FileDownloader
          loadingMessage='Preparing transactions, just a moment...'
          startDownload={() => downloadTournamentTransactions(instance, account!, tournamentId!)} />
      </Modal>
      {showRefundDialog &&
        <RefundDialog
          tournamentId={tournamentId!}
          transaction={items?.find(i => i.key === selection.getSelection()[0].key!)!.transaction}
          relatedTransactions={items?.filter(i => i.transaction.UserId === selectedItem?.transaction.UserId).map(i => i.transaction)}
          onRefundCompleted={onRefundCompleted}
          onDismiss={()=>setShowRefundDialog(false)}
        />}
        <Dialog
          hidden={hideResendReceiptDialog}
          onDismiss={toggleResendReceiptDialog}
          dialogContentProps={dialogContentProps}
          modalProps={{isBlocking:true}}>
        <DialogFooter>
          <PrimaryButton onClick={requestReceiptResend} text="Resend" />
          <DefaultButton onClick={toggleResendReceiptDialog} text="Don't resend" />
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default TransactionManagement;
