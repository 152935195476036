import { useEffect, useState } from "react";
import { getCurriculumSubmissionEnv, getBulkInviteEnv, getCurriculumEnv, getTournamentEnv } from './ApiService';
import { useIsAuthenticated, useMsal, useAccount } from "@azure/msal-react";
import { AnonymousCredential, BlobServiceClient } from "@azure/storage-blob";
import { SubmissionEnv } from "./model/SubmissionEnv";

export class StorageService {
   
    submissionEnv:SubmissionEnv;
    blobServiceClient: BlobServiceClient;

    constructor(submissionEnv:SubmissionEnv) {
        this.submissionEnv = submissionEnv;
        this.blobServiceClient = new BlobServiceClient(
            `${submissionEnv.Endpoint}${submissionEnv.SasUri}`,
            new AnonymousCredential()
        );
    }

    uploadFile = async (blob: Blob) : Promise<boolean> =>  {
        try
        {
            var containerClient = this.blobServiceClient.getContainerClient(this.submissionEnv.ContainerName);            
            var blobClient = containerClient.getBlobClient(this.submissionEnv.Filename);
            var blockBlobClient = blobClient.getBlockBlobClient();

            await blockBlobClient.uploadData(blob, { blobHTTPHeaders: { blobContentType: blob.type } });

            return true;
        }
        catch{
            alert('Something went wrong with the upload. Please refresh the page and try again.');
            return false;
        }
    };
}

export enum StoragePurpose {
    StudentCurriculumSubmission = 0,
    General = 1,
    Curriculum = 2,
    Tournament = 3
}

export const useSubmissionStorage  = (storagePurpose: StoragePurpose, studentId?: string, curriculumId?: string) : StorageService | undefined => {
    const [storage, setStorage] = useState<StorageService>(); 
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [isFetching, setIsFetching] = useState(false);
    useEffect(()=> {
        if(!isAuthenticated || storage !== undefined || isFetching) {
            return;
        }

        const fetchData = async () => {                            
            try {
                if(account) {

                    var submissionEnv : SubmissionEnv | undefined;

                    switch(storagePurpose) {
                        case StoragePurpose.StudentCurriculumSubmission: {
                            submissionEnv = await getCurriculumSubmissionEnv(instance, account, studentId!, curriculumId!);
                            break;
                        }
                        case StoragePurpose.General: {
                            submissionEnv = await getBulkInviteEnv(instance, account);
                            break;
                        }
                        case StoragePurpose.Curriculum: {
                            submissionEnv = await getCurriculumEnv(instance, account);
                            break;
                        }
                        case StoragePurpose.Tournament: {
                            submissionEnv = await getTournamentEnv(instance, account);
                        }
                    }                            

                    let service = new StorageService(submissionEnv!);
                    setStorage(service);
                    setIsFetching(false);
                }
            }                            
            catch(error) {
                console.log('unable to get submission env: ' + error);
            }
        }

        if (inProgress === "none" && account) {
            setIsFetching(true);
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inProgress, account, instance, curriculumId]);

    return storage;
}