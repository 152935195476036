import { TournamentLayout, TournamentType } from "./Tournament";

export interface ILandingPageEvent {
    ImageUri: string;
    Title: string;
}

export interface ILandingPageTournament {
    Id: string;
    ImageUri: string;
    Title: string;
    Location: string;
    Timezone: string;
    Type: TournamentType;
    Layout: TournamentLayout;
    Description: string;
    RegistrationEndDate: Date;
    StartDate?: Date;
    EndDate?: Date;
}

export interface ITenantLandingPageViewModel {
    EnvTitle: string;
    EnvDescription: string;
    CanJoin: boolean;
    Tournaments: ILandingPageTournament[];
}

export class TenantLandingPageViewModel implements ITenantLandingPageViewModel {    
    EnvDescription: string;
    EnvTitle: string;
    CanJoin: boolean;
    Tournaments: ILandingPageTournament[];
    
    constructor(params: ITenantLandingPageViewModel = {} as ITenantLandingPageViewModel) {
            
        let {
            EnvDescription = '',
            EnvTitle = '',
            CanJoin = false,
            Tournaments = [],
        } = params;
            
        this.EnvDescription = EnvDescription;
        this.EnvTitle = EnvTitle;
        this.CanJoin = CanJoin;
        this.Tournaments = Tournaments;
    }
}