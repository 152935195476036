import React, { FC, useEffect, useState } from 'react';
import { Selection, IColumn, SelectionMode, Stack, CheckboxVisibility, SearchBox, Checkbox, Text, IconButton, Modal, IButtonStyles, FontWeights, getTheme, IIconProps, mergeStyleSets, CommandBar, ICommandBarItemProps, IContextualMenuItem, Panel, PanelType, ContextualMenuItemType, IPersonaProps } from '@fluentui/react';
import { useMsal, useAccount } from "@azure/msal-react";
import { getAllTournamentRegistrations, getRegistrationsForTournament, downloadTournamentRegistrations, downloadTournamentBadges, postStudentCurriculumSubmission, downloadTournamentBrackets, downloadTournamentBadgeForStudent, downloadTournamentCredentials, downloadSingleTournamentCredential, downloadBlankTournamentCredential, findStudentByName } from '../../../ApiService';
import Loader from '../../../components/Loader';
import List, { IDocument } from '../../../components/List';
import { TournamentRegistrationViewModel } from '../../../model/TournamentRegistrationViewModel';
import { RegistrationStatus, TournamentRegistration, TournamentRegistrationStatus } from '../../../model/TournamentRegistration';
import { useBoolean } from '@fluentui/react-hooks';
import StudentVideoCapture from '../../../components/StudentVideoCapture';
import { CameraIcon, UploadIcon } from '@fluentui/react-icons-mdl2';
import StudentVideoUploader from '../../../components/StudentVideoUploader';
import { TournamentType } from '../../../model/Tournament';
import RegistrationDetails from './RegistrationDetails';
import { IUserInput } from '../../../model/CatalogItem';
import FileDownloader from '../../../components/FileDownloader';
import RegistrationEditor from './RegistrationEditor';
import { IUploadedFile } from '../../../components/FileUploadButton';
import { IFileDownload } from '../../../model/FileDownload';
import BulkImporter from '../../../components/BulkImporter';
import StudentPicker, { IStakeholderPersonaProps } from '../../../components/StudentPicker';

interface ITableRow extends IDocument {
  key: string;
  tournament: string;
  tournamentId: string;
  tournamentType: TournamentType;
  trainingFacility: string;
  transactionIds: string[];
  student: string;
  studentId: string;
  level: string;
  user: string;
  email: string;
  event: string;
  eventId: string;
  status: RegistrationStatus;
  registered: Date;
  entered: boolean;
  inputs?: IUserInput[];
}

interface IData {
  tournamentId?: string;
}

const RegistrationManagement : FC<IData> = ({tournamentId}) => {
  const { instance, accounts } = useMsal();    
  const account = useAccount(accounts[0] || {});
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [items, setItems] = useState<ITableRow[]>();
  const [allItems, setAllItems] = useState<ITableRow[]>();
  const [allRegistrations, setAllRegistrations] = useState<TournamentRegistrationViewModel>();
  const [isTableLocked, ] = useState<boolean>(false);
  const [hidePendingRegistrations, setHidePendingRegistrations] = useState<boolean>(true);
  const [incompleteRegistrations, setIncompleteRegistrations] = useState<number>(0);
  const [selection, setSelection] = useState<Selection>(new Selection());
  const [isDownloadModalOpen, { setTrue: showDownloadModal, setFalse: hideDownloadModal }] = useBoolean(false);
  
  const [isBulkImportOpen, { setTrue: showBulkImport, setFalse: hideBulkImport }] = useBoolean(false);

  enum ActionType {
    Film = 0,
    Upload = 1,
    ViewDetails = 2,
    NewRegistration = 3,
    AddRegistration = 4,
    ChangeRegistration = 5
  }
  
  enum ExportTypeEnum {
    None = -1,
    Registratons = 0,
    Credentials = 1,
    Brackets = 2,
    SingleCredential = 3,
    BlankCredential = 4
  }

  const [nearCredentialDownloadSubItems, setNearCredentialDownloadSubItems] = useState<IContextualMenuItem[]>([]);
  const [blankCredentialDownloadSubItems, setBlankCredentialDownloadSubItems] = useState<IContextualMenuItem[]>([]);
  const [farCredentialDownloadSubItems, setFarCredentialDownloadSubItems] = useState<IContextualMenuItem[]>([]);


const [exportType, setExportType] = useState<ExportTypeEnum>();
const [templateId, setTemplateId] = useState<string>();

const nearCommandBarItems : ICommandBarItemProps [] = [  
  {
    key: 'newRego',
    text: 'New Registration',
    iconProps: { iconName: 'AddFriend' },
    onClick: () => { newRegistration() }
  },
  {
    key: 'edit',
    text: 'Modify',
    iconProps: { iconName: 'FieldNotChanged' },
    disabled: selection.count === 0,
    subMenuProps: {
      items: [
        {
          key: 'addEvent',
          text: 'Add Event',
          iconProps: { iconName: 'Add' },
          onClick: () => { addRegistration(items?.find(i => i.key === selection.getSelection()[0].key!)!) }
        },
        {
          key: 'changeEvent',
          text: 'Swap Event',
          iconProps: { iconName: 'ChangeEntitlements' },
          onClick: () => { changeRegistration(items?.find(i => i.key === selection.getSelection()[0].key!)!) }
        }
      ]
    
    }
  },
  {
    key:'view',
    text: 'View Details',
    iconProps: {iconName:'EntryView'},
    disabled: selection.count === 0,
    onClick: () => {viewDetails(items?.find(i=>i.key === selection.getSelection()[0].key!)!)}
  },
  {
    key:'download',
    text: 'Download',
    iconProps:{iconName:'CloudDownload'},
    subMenuProps:{
      items: [        
        {
          key:'bracketDownload',
          text: 'Brackets',
          iconProps: {iconName:'Org'},
          onClick: () => {setExportType(ExportTypeEnum.Brackets)}
        },
        {
          key:'credentialsParent',
          text: 'Credentials',
          iconProps: { iconName: 'IDBadge' },
          subMenuProps: {
            items: [{
              key: 'downloadAllCredentials',
              text: 'All',
              iconProps: { iconName: 'IDBadge' },
              subMenuProps: {
                items: farCredentialDownloadSubItems
              }
            },
            {
              key:'downloadCredential',
              text: 'Selected',
              iconProps: {iconName:'IDBadge'},
              disabled: selection.count === 0,
              subMenuProps: {
                items: nearCredentialDownloadSubItems
              }
              },
              { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
            {
              key:'blankCredential',
              text: 'Blank',
              iconProps: {iconName:'IDBadge'},
              subMenuProps: {
                items: blankCredentialDownloadSubItems
              }
            }]
          }
        },
        {
          key:'regoDownload',
          text: 'Registrations',
          iconProps: {iconName:'ExcelDocument'},
          onClick: () => {setExportType(ExportTypeEnum.Registratons)}
        },        
      ]
    } 
  }
];

  const farCommandBarItems: ICommandBarItemProps[] = [{
    key: 'bulkImport',
    text: 'Import Registrations',
    iconProps: { iconName: 'Upload' },
    disabled: false,
    onClick: () => { showBulkImport(); }    
}];

  const [columns,] = useState<IColumn[]>([
    {
      key: 'column1',
      name: 'Item',
      fieldName: 'event',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      isCollapsible: false,
      data: 'string',
      isPadded: true,
      onRender: (item: ITableRow) => {
        return <span style={{ color: item.status === RegistrationStatus.Complete ? 'initial' : 'red' }}>{item.event}</span>
      }
    },
    {
      key: 'column2',
      name: 'Student',
      fieldName: 'student',
      minWidth: 75,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onRender: (item: ITableRow) => {
        return <span style={{ color: item.status === RegistrationStatus.Complete ? 'initial' : 'red' }}>{item.student}</span>
      }
    },
    {
      key: 'column3',
      name: 'Level',
      fieldName: 'level',
      minWidth: 40,
      maxWidth: 70,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onRender: (item: ITableRow) => {
        return <span style={{ color: item.status === RegistrationStatus.Complete ? 'initial' : 'red' }}>{item.level}</span>
      }
    },
    {
      key: 'column3a',
      name: 'Training Facility',
      fieldName: 'trainingFacility',
      minWidth: 75,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onRender: (item: ITableRow) => {
        return <span style={{ color: item.status === RegistrationStatus.Complete ? 'initial' : 'red' }}>{item.trainingFacility}</span>
      }
    },
    {
      key: 'column4',
      name: 'Registered by',
      fieldName: 'user',
      minWidth: 50,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'number',
      isPadded: true,
      onRender: (item: ITableRow) => {
        return <span style={{ color: item.status === RegistrationStatus.Complete ? 'initial' : 'red' }}>{item.user}</span>
      }
    },
    {
      key: 'column5',
      name: 'Registered',
      fieldName: 'registered',
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      isCollapsible: true,
      data: 'date',
      isPadded: true,
      onRender: (item: ITableRow) => {
        return <span style={{ color: item.status === RegistrationStatus.Complete ? 'initial' : 'red' }}>{item.registered.toLocaleString()}{item.status === RegistrationStatus.Complete ? null : <span>{` (${getStatusString(item.status)})`}</span>}</span>;
      }
    },
    {
      key: 'column6',
      name: 'Status',
      fieldName: 'status',
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      isCollapsible: true,
      data: 'date',
      isPadded: true,
      onRender: (item: ITableRow) => {
        return <span style={{ color: item.status === RegistrationStatus.Complete ? 'initial' : 'red' }}>{getStatusString(item.status)}</span>;
      }
    },
    {
      key: 'column9',
      name: 'Actions',
      minWidth: 60,
      maxWidth: 70,
      isResizable: true,
      isCollapsible: false,
      isPadded: true,
      onRender: (item: ITableRow) => {
        return item.tournamentType === TournamentType.InPerson ? null :
          item.entered ?
            null :
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <IconButton onRenderIcon={() => <CameraIcon onClick={() => filmOnBehalfOf(item)} />} />
              <IconButton onRenderIcon={() => <UploadIcon onClick={() => uploadOnBehalfOf(item)} />} />
            </Stack>
      }
    }
  ]);

  const getStatusString = (registrationStatus: RegistrationStatus) => {
    switch (registrationStatus) {
      case RegistrationStatus.Complete: {
        return `Completed`;
      }
      case RegistrationStatus.Pending: {
        return "Pending";
      }
      case RegistrationStatus.Canceled: {
        return "Canceled";
      }
    }
  }
  
  const filmOnBehalfOf = (item: ITableRow) => {
    setDesiredAction(ActionType.Film);
    setSelectedItem(item);
  }
  const uploadOnBehalfOf = (item: ITableRow) => {
    setDesiredAction(ActionType.Upload);
    setSelectedItem(item);
  }

  const viewDetails = (item: ITableRow) => {
    setDesiredAction(ActionType.ViewDetails);
    setSelectedItem(item);
  }

  const newRegistration = () => {
    setDesiredAction(ActionType.NewRegistration);
    showNewModal();
  }

  const addRegistration = (item: ITableRow) => {
    setDesiredAction(ActionType.AddRegistration);
    setSelectedItem(item);
  }

  const changeRegistration = (item: ITableRow) => {
    setDesiredAction(ActionType.ChangeRegistration);
    setSelectedItem(item);
  }

    const [errorCode, setErrorCode] = useState<number>();

    useEffect(()=> {
      if(errorCode === undefined) {
        return;
      }

      throw new Error("The server returned status code: " + errorCode);
    },[errorCode]);

    useEffect(()=> {
        const fetchAsync = async () => {
          var allRegistrations : TournamentRegistrationViewModel | number;

          if(tournamentId !== undefined) {
            allRegistrations = await getRegistrationsForTournament(instance, account!, tournamentId);
          }
          else {
            allRegistrations = await getAllTournamentRegistrations(instance, account!);
          }

          if(typeof allRegistrations === 'number') {
            setErrorCode(allRegistrations);
            return;
          }
          
          setAllRegistrations(allRegistrations);
          setIsLoaded(true);
        }

        if(account) {
            fetchAsync();
        }
    },[instance, account, tournamentId]);

  useEffect(() => {
    if (allRegistrations === undefined) {
      return;
    }

    var registrations = allRegistrations?.Registrations;

    if (hidePendingRegistrations) {
      registrations = allRegistrations?.Registrations.filter(r => r.Status !== TournamentRegistrationStatus.Pending);
    }

    //registrations are incomplete if they are in the pending state (all child events are incomplete) or pending addition state (some child events are incompelete)
    //given we show each event as a row, it should calculate all UI elements based on the child events

    var tableRows = buildTableRows(registrations, allRegistrations);
      
    //menu items for the templates

    let blankTemplateItems: Array<ICommandBarItemProps> = [];

    let singleDownloadTemplateItems: Array<ICommandBarItemProps> = [
      {
        key: 'usletter',
        text: 'US Letter Paper',
        iconProps: { iconName: 'Print' },
        onClick: (event, item) => { setTemplateId(item?.key); setExportType(ExportTypeEnum.SingleCredential); }
      }
    ];

    let downloadAllTemplateItems: Array<ICommandBarItemProps> = [
      {
        key: 'usletter',
        text: 'US Letter Paper',
        iconProps: { iconName: 'Print' },
        onClick: (event, item) => { setTemplateId(item?.key); setExportType(ExportTypeEnum.Credentials); }
      }
    ];
      
    for (let template of allRegistrations.Tournaments.find(t => t.Id === tournamentId)!.CredentialTemplates) {
      singleDownloadTemplateItems.push({
        key: template.Id,
        text: template.DisplayName,
        iconProps: { iconName: 'Print' },
        onClick: (event, item) => { setTemplateId(item?.key); setExportType(ExportTypeEnum.SingleCredential); }
      });
        
      downloadAllTemplateItems.push({
        key: template.Id,
        text: template.DisplayName,
        iconProps: { iconName: 'Print' },
        onClick: (event, item) => { setTemplateId(item?.key); setExportType(ExportTypeEnum.Credentials); }
      });

      blankTemplateItems.push({
        key: template.Id,
        text: template.DisplayName,
        iconProps: { iconName: 'Print' },
        onClick: (event, item) => { setTemplateId(item?.key); setExportType(ExportTypeEnum.BlankCredential); }
      })
    }

    setFarCredentialDownloadSubItems(downloadAllTemplateItems);
    setNearCredentialDownloadSubItems(singleDownloadTemplateItems);
    setBlankCredentialDownloadSubItems(blankTemplateItems);

    setAllItems(tableRows);
    setItems([...tableRows]);
    setIncompleteRegistrations(allRegistrations?.Registrations.filter(t => t.Status === TournamentRegistrationStatus.Pending).length);
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  },[allRegistrations]);

  useEffect(() => {
    if (allRegistrations === undefined) {
      return;
    }
    var registrations = allRegistrations.Registrations;

    if (hidePendingRegistrations) {
      registrations = allRegistrations.Registrations.filter(t => t.Status !== TournamentRegistrationStatus.Pending);
    }
        
    var tableRows = buildTableRows(registrations, allRegistrations);
    setItems([...tableRows]);
    setAllItems([...tableRows]);
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [hidePendingRegistrations]);

  const doSearch = (n: string | undefined) => {
    if (n === undefined || n.length === 0) {
      //rebuild the default list
      var registrations = allRegistrations!.Registrations;

      if (hidePendingRegistrations) {
        registrations = allRegistrations!.Registrations.filter(t => t.Status !== TournamentRegistrationStatus.Pending);
      }
      var defaultList = buildTableRows(registrations, allRegistrations!);
      setItems(defaultList);
      return;
    }
  
    var filtered = allItems?.filter(i =>
      i.event.toLowerCase().indexOf(n.toLowerCase()) !== -1 ||
      i.student.toLowerCase().indexOf(n.toLowerCase()) !== -1 ||
      i.user.toLowerCase().indexOf(n.toLowerCase()) !== -1 ||
      i.email.toLowerCase().indexOf(n.toLowerCase()) !== -1);
      
    setItems([...filtered!]);
  }

  const buildTableRows = (registrations: TournamentRegistration[], vm: TournamentRegistrationViewModel) => {
    var tableRows = new Array<ITableRow>();

    for (let tournament of vm.Tournaments) {
      let tournamentTitle = `${tournament.Title}`;
      let tId = tournament.Id;
      let dateRange = `${tournament.StartDate.toLocaleDateString()} - ${tournament.EndDate.toLocaleDateString()}`
      let type = tournament.Type;

      var filteredRegos = registrations.filter(r => r.TournamentId === tId);

      for (let registration of filteredRegos) {
        let r = registration;

        //create a row per event registration
        for (let event of registration.Events) {
          let e = event;
          let title = `${vm.Curriculum.find(c => c.Id === e.CurriculumId)!.Name} - ${vm.Students.find(s => s.Id === e.StudentId)!.FirstName}`;
                    
          if (hidePendingRegistrations && e.Status === RegistrationStatus.Pending) {
            continue;
          }

          tableRows.push({
            key: `${e.CurriculumId}_${e.StudentId}`,
            registered: new Date(e.Created),
            entered: e.SubmissionId !== null && e.SubmissionId.length > 0,
            event: vm.Curriculum.find(c => c.Id === e.CurriculumId)!.Name,
            inputs: tournament.Events.find(t => t.EventId === e.CurriculumId)!.Inputs,
            eventId: e.CurriculumId,
            studentId: e.StudentId,
            student: `${vm.Students.find(s => s.Id === e.StudentId)!.FirstName} ${vm.Students.find(s => s.Id === e.StudentId)!.LastName}`,
            level: vm.Students.find(s => s.Id === e.StudentId)!.Level!.Label,
            tournament: tournamentTitle,
            tournamentType: type,
            tournamentId: tId,
            trainingFacility: vm.Students.find(s => s.Id === e.StudentId)!.TrainingFacility,
            transactionIds: registration.TransactionIds,
            groupHeader: tournamentId === undefined ? tournamentTitle : undefined,
            groupSubheader: tournamentId === undefined ? dateRange : undefined,
            groupKey: tournamentId === undefined ? tournamentTitle : undefined,
            user: vm.Users.find(u => u.Id === r.UserId)!.DisplayName,
            email: vm.Users.find(u => u.Id === r.UserId)!.Email,
            status: event.Status,
            getTitle: () => title
          });
        }
                                
        //and another row for each catalog item
        for (let catalog of registration.Catalog) {
          let c = catalog;

          var catItem = vm.Catalog.find(cI => cI.Id === c.CatalogItemId);
                  
          let title = `${catItem?.Name} ${c.Config?.map(i => `${i.Key} ${i.Value}`)} x${c.Qty} - ${vm.Users.find(u => u.Id === r.UserId)!.DisplayName}`;

          tableRows.push({
            key: `${c.CatalogItemId}_${c.Qty}_${c.Created.getUTCMilliseconds()}_${r.UserId}`,
            registered: new Date(registration.Created),
            entered: false,
            event: `${catItem?.Name} ${c.Config?.map(i => `${i.Key} ${i.Value}`)} x${c.Qty}`,
            eventId: c.CatalogItemId,
            studentId: '',
            student: '',
            level: '',
            tournament: tournamentTitle,
            tournamentType: type,
            tournamentId: tId,
            transactionIds:registration.TransactionIds,
            groupHeader: tournamentId === undefined ? tournamentTitle : undefined,
            groupSubheader: tournamentId === undefined ? dateRange : undefined,
            groupKey: tournamentId === undefined ? tournamentTitle : undefined,
            user: vm.Users.find(u => u.Id === r.UserId)!.DisplayName,
            email: vm.Users.find(u => u.Id === r.UserId)!.Email,
            status: c.Status,
            trainingFacility: '',
            getTitle: () => title
          });
        }
      }
    }
  
    return tableRows;
  }

    const [selectedItem, setSelectedItem] = useState<ITableRow>();      

    const [desiredAction, setDesiredAction] = useState<ActionType>();

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 0px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });
    
    const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
    };

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [isNewModalOpen, { setTrue: showNewModal, setFalse: hideNewModal }] = useBoolean(false);

    useEffect(() => {      
      if(!isModalOpen) {
        showModal();
      }
      // eslint-disable-next-line
    },[selectedItem]);

    const onFileUploaded = async (uploadedFile:IUploadedFile) => {
        var result = await postStudentCurriculumSubmission(instance, account!, selectedItem!.eventId, selectedItem!.studentId, uploadedFile.relativeFilePath, uploadedFile.type);

        if(result?.ok) {
            hideModal();
        }
        else {
            alert('Something went wrong with the upload. Please refresh the page and try again.');
        }      
      }

    useEffect(()=> {
      if (exportType === undefined ||
        (templateId === undefined && (exportType === ExportTypeEnum.SingleCredential || exportType === ExportTypeEnum.Credentials || exportType === ExportTypeEnum.BlankCredential))) {
        return;
      }

      showDownloadModal();
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[exportType, templateId]);

    
    const onDismissModal = () => {
      setDesiredAction(undefined); 
      setSelectedItem(undefined);
      hideModal();
    }    

    const [activeItem, setActiveItem] = useState<ITableRow>();

    const onActiveItemChanged = (item?: ITableRow, index?: number) => {      
      setActiveItem(item);
   }

  const startDownload = async (): Promise<number | IFileDownload> => {
    switch (exportType) {
      case ExportTypeEnum.SingleCredential:
        {
          if (templateId === undefined || templateId === 'usletter') {            
            return downloadTournamentBadgeForStudent(instance, account!, tournamentId!, activeItem!.studentId);
          }

          return downloadSingleTournamentCredential(instance, account!, tournamentId!, templateId!, activeItem!.studentId);
        }
      case ExportTypeEnum.BlankCredential:
        {
          return downloadBlankTournamentCredential(instance, account!, tournamentId!, templateId!);
        }
      case ExportTypeEnum.Credentials:
        {
          if (templateId === undefined || templateId === 'usletter') {
            return downloadTournamentBadges(instance, account!, tournamentId!);
          }

          return downloadTournamentCredentials(instance, account!, tournamentId!, templateId!);
        }
      case ExportTypeEnum.Registratons:
        {
          return downloadTournamentRegistrations(instance, account!, tournamentId!);
        }
      case ExportTypeEnum.Brackets:
        {
          return downloadTournamentBrackets(instance, account!, tournamentId!);
        }
    }

    return 400;
  }

  const onResolveSuggestions = (filter: string, selectedItems?: IPersonaProps[] | undefined) : IPersonaProps[] | PromiseLike<IPersonaProps[]> => {
    return new Promise<IPersonaProps[]>(async (resolve, reject) => {
        var results = await findStudentByName(instance, account!, tournamentId!, filter);

        if(typeof results === 'number') {
            reject(results);
            return;
        }

        var toReturn : IStakeholderPersonaProps[]  = [];

        for(let r of results) {            

          toReturn.push({
            imageInitials: `${r.DisplayName.split(' ')[0][0]}${r.DisplayName.split(' ')[1][0]}`,
            text: r.DisplayName,
            secondaryText: r.Email,
            showSecondaryText: true,
            membershipId: r.MembershipId,
            email: r.Email,
            studentId: r.StudentId,
            trainingFacility: r.TrainingFacility
          });
        }
        resolve(toReturn);
    });        
  }
  
  const [newStudentToRegister, setNewStudentToRegister] = useState<IStakeholderPersonaProps>();

  const getAlreadyRegisteredIds = () => {
    let studentId = '';
    if (selectedItem === undefined) {
      //must be using the new student picker method
      studentId = newStudentToRegister!.studentId;
    }
    else {
      studentId = selectedItem.studentId;
    }
    
    return allRegistrations?.Registrations.filter(r =>
      r.Status !== TournamentRegistrationStatus.Pending &&
      r.Events.find(e =>
        e.StudentId === studentId &&
        e.Status === RegistrationStatus.Complete) != null)
      .map(r => r.Events.filter(e => e.StudentId === studentId && e.Status === RegistrationStatus.Complete).map(e => e.CurriculumId)).flat()!
  }

    return (
          <>
          {isLoaded ?
              items !== undefined && allRegistrations !== undefined && allRegistrations.Registrations.length > 0 ? 
                  <Stack tokens={{childrenGap:10}}>
                    <br />                                       
                    <Stack.Item align='end'>
                      <SearchBox placeholder="Find registrations" onChange={(e,n) => doSearch(n)} />
                    </Stack.Item>
                    <Stack.Item>                        
                      {incompleteRegistrations > 0 ?
                        <Stack horizontalAlign='end'>
                          <Checkbox label='Hide pending' onChange={(e,v) => setHidePendingRegistrations(v!)} checked={hidePendingRegistrations} />
                          <Stack.Item>
                            <Text variant='small'>({incompleteRegistrations} pending registrations)</Text>
                          </Stack.Item>
                        </Stack>
                        : null
                      }
                    </Stack.Item> 
                  
                    {tournamentId !== undefined ? <CommandBar
                      items={nearCommandBarItems}
                      farItems={farCommandBarItems} />
                    : null} 
              <List
                        columns={columns} 
                        isLocked={isTableLocked}
                        selectionMode={SelectionMode.single}   
                        onActiveItemChanged={onActiveItemChanged}
                        enableSort   
                        isGrouped={tournamentId === undefined}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        items={items}
                        onSelectionChanged={(s)=>setSelection(s)}/>
                  </Stack>
                  : <div style={{paddingTop:20, paddingLeft:10}}><Text>No registrations yet.</Text></div>
              :
              <Loader Text='Just a moment...' />
          }
          {selectedItem !== undefined ?
          <Modal
            isOpen={isModalOpen}
            isBlocking
            onDismiss={onDismissModal}
            containerClassName={contentStyles.container}>
            <div className={contentStyles.header}>
                <span>{selectedItem.student.length === 0 ? selectedItem.user : selectedItem.student}</span>                
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close modal"
                    onClick={onDismissModal} />
            </div>
            <div className={contentStyles.body}>   
              {desiredAction === ActionType.Film ?
                <StudentVideoCapture CurriculumId={selectedItem.eventId} StudentId={selectedItem.studentId} /> : null }
              {desiredAction === ActionType.Upload ?
                <StudentVideoUploader curriculumId={selectedItem.eventId} studentId={selectedItem.studentId} onFileUploaded={onFileUploaded} /> : null }
                {desiredAction === ActionType.ViewDetails ?
                <>
                  <Text style={{paddingLeft:5}} variant='small'>{selectedItem.email}</Text>
                  <RegistrationDetails 
                    inputs={selectedItem?.inputs!}
                    tournament={allRegistrations?.Tournaments.find(t=>t.Id === selectedItem.tournamentId)!}
                    catalogItem={allRegistrations?.Catalog.find(c=>c.Id === selectedItem.eventId)!} 
                    curriculum={allRegistrations?.Curriculum.find(c=>c.Id === selectedItem.eventId)!} 
                    student={allRegistrations?.Students.find(s=>s.Id === selectedItem.studentId)!}
                    registration={allRegistrations?.Catalog.find(c=>c.Id === selectedItem.eventId) !== undefined ? allRegistrations!.Registrations.find(r=>r.Catalog.find(e=>e.CatalogItemId === selectedItem.eventId) !== undefined)! : allRegistrations!.Registrations.find(r=>r.Events.find(e=>e.CurriculumId === selectedItem.eventId && e.StudentId === selectedItem.studentId) !== undefined)!} /> 
                </>
                  : null}
              {desiredAction === ActionType.ChangeRegistration ||
                desiredAction === ActionType.AddRegistration ?
                <>
                  <Text style={{ paddingLeft: 5 }} variant='small'>Customer email: {selectedItem.email}</Text>
                  <RegistrationEditor
                    alreadyRegisteredIds={getAlreadyRegisteredIds()}
                    eventName={selectedItem.event}
                    onCompleted={onDismissModal}
                    eventIdToRemove={desiredAction === ActionType.ChangeRegistration ? selectedItem.eventId : undefined}
                    customerEmail={selectedItem.email}
                    studentId={selectedItem.studentId}
                    tournamentId={tournamentId!} />
                </>
                : null}              
            </div>
        </Modal> : null}
          <Modal
            isOpen={isDownloadModalOpen}
            onDismiss={() => { setExportType(ExportTypeEnum.None); setTemplateId(undefined); hideDownloadModal();}}            
            containerClassName={contentStyles.container}>
           <FileDownloader 
                  loadingMessage={ exportType === ExportTypeEnum.SingleCredential ? 'Preparing credential, just a moment' : exportType === ExportTypeEnum.Credentials ? 
                    'Preparing credentials, just a moment...' : 
                    exportType === ExportTypeEnum.Registratons ? 
                    'Preparing registrations, just a moment...' : 'Preparing brackets, just a moment...'}
                  startDownload={startDownload} />
        </Modal>

        <Modal
          isOpen={isNewModalOpen}
            isBlocking
            onDismiss={() => { setNewStudentToRegister(undefined); hideNewModal(); }}
            containerClassName={contentStyles.container}>
            <div className={contentStyles.header}>
                <span>Find student</span>                
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close modal"
                    onClick={() => { setNewStudentToRegister(undefined); hideNewModal(); }} />
            </div>
            <div className={contentStyles.body}>   
            <br />
            <Stack tokens={{childrenGap:10}}>
              <StudentPicker tournamentId={tournamentId!} onResolveSuggestions={onResolveSuggestions} onChange={(items: IStakeholderPersonaProps[] | undefined) => setNewStudentToRegister(items === undefined ? undefined : items[0])} />

              {newStudentToRegister !== undefined &&
                <RegistrationEditor
                  alreadyRegisteredIds={getAlreadyRegisteredIds()}
                  customerEmail={newStudentToRegister!.email}
                  onCompleted={() => { setNewStudentToRegister(undefined); hideNewModal(); }}
                  tournamentId={tournamentId!}
                  studentId={newStudentToRegister.studentId} />
              }
            </Stack>
            </div>
        </Modal>

        <Panel
            headerText={"Import Registrations"}
            type={PanelType.smallFixedFar}
            isOpen={isBulkImportOpen}
            isLightDismiss={true}
            onDismiss={hideBulkImport}
            closeButtonAriaLabel="Close">
          <BulkImporter
            tournamentId={tournamentId!}
            importCompleted={hideBulkImport} />
        </Panel>
      </>
    )
}

export default RegistrationManagement;