import {FC, useEffect, useState} from 'react';
import { getMembership, getTenantEnvironment, getTournament, getTournamentRegistrations } from '../../ApiService';
import { IPricingStrategy, Tournament, TournamentLayout, TournamentRole, TournamentType } from '../../model/Tournament';
import { Student } from '../../model/Student';
import { useAccount, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { DefaultButton, Dialog, DialogFooter, DialogType, Panel, Pivot, PivotItem, PrimaryButton, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import CountdownTimer from '../../components/CountdownTimer';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import Leaderboard from './components/Leaderboard';
import { InteractionType } from '@azure/msal-browser';
import { ITournamentCatalogItemPurchase, ITournamentEventRegistration, ITournamentRegistration, RegistrationStatus, TournamentRegistration, TournamentRegistrationStatus } from '../../model/TournamentRegistration';
import { IPurchasePrerequisite, PurchasePrerequisiteType, TournamentEvent } from '../../model/TournamentEvent';
import { loadStripe } from '@stripe/stripe-js';
import TournamentEventCard, {EventCardDisplayMode, IStudentWithRegistrationPermission} from './components/TournamentEventCard';
import { DateTime } from 'luxon';
import TournamentViewer from './components/TournamentViewer';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import RegisterTournament from './components/RegisterTournament';
import CatalogItemCard from './components/CatalogItemCard';
import EventRegistrations from '../Dashboard/components/EventRegistrations';
import { CanRegister } from '../../Validation';

interface IEventGroup {
    Title: string;
    Subtitle: string;
    Events: TournamentEvent[]; 
}
export enum Mode {
    Register = 0,
    Enter = 1,
    Watch = 2
}

const TournamentDashboard : FC = () => {
    let { tournamentId } = useParams<{tournamentId: string }>() as {tournamentId: string };
    let { mode } = useParams<{mode:string}>() as {mode:string};

    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});
    
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [stripePromise, setStripePromise] = useState<any>();
    const navigate = useNavigate();
    
    const [dashboardMode, setDashboardMode] = useState<Mode>(mode === undefined ? Mode.Register : mode.toLowerCase() === 'enter' ? Mode.Enter : mode.toLowerCase() === 'watch' ? Mode.Watch : Mode.Register);
    const [tournament, setTournament] = useState<Tournament>();
    const [students, setStudents] = useState<Student[]>();
    const [groupedEvents, setGroupedEvents] = useState<IEventGroup[]>();

    const [hasTournamentStarted, setHasTournamentStarted] = useState<boolean>(true);
    const [hasTournamentEnded, setHasTournamentEnded] = useState<boolean>(true);
    const [tournamentRegistrations, setTournamentRegistrations] = useState<TournamentRegistration[]>();
    const [pendingRegistration, setPendingRegistration] = useState<TournamentRegistration>();

    const [canRegisterForAdditionalEvents, setCanRegisterForAdditionalEvents] = useState<boolean>(false);
    const [canRegister, setCanRegister] = useState<boolean>(false);
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });
    const [isCompleteRegistrationOpen, { setTrue: openCompleteRegistration, setFalse: dismissCompleteRegistration }] = useBoolean(false);
    
    useEffect(()=>{
        const fetchData = async () => {
            if (inProgress === "none" && account) {                
                var env = await getTenantEnvironment(instance, account);
                setStripePromise(loadStripe(env.PaymentGatewayPublicToken));
                
                var membershipResult = await getMembership(instance, account); 
                
                if (membershipResult.StatusCode === 200) {
                    let dateNinetyDaysAgo = DateTime.utc().minus({ days: 90 });

                    if ((membershipResult.Result!.Modified === undefined &&
                        DateTime.fromJSDate(membershipResult.Result!.Created) < dateNinetyDaysAgo) ||
                        DateTime.fromJSDate(membershipResult.Result!.Modified) < dateNinetyDaysAgo) {
                        navigate('/user/review');
                        return;
                    }
    

                    setStudents(membershipResult.Result!.Students);

                    var tournament = await getTournament(instance, account, tournamentId);
                    var regos = await getTournamentRegistrations(instance, account, tournamentId);
                    
                    if(regos !== undefined) {
                        setTournamentRegistrations([...regos.filter(s=>s.Status !== TournamentRegistrationStatus.Pending)]);
                    }

                    //group the events
                    var groupedByKey : IEventGroup[] = [];

                    for(let event of tournament.Events) {
                        let e = event;

                        if (e.Students.length > 0) {
                            let purchaseGroup = tournament.PurchaseGroups.find(p => p.Id === e.PurchaseGroupId);
                            let title = purchaseGroup === undefined ? e.Subcategory : purchaseGroup.Title;
                            let subtitle = purchaseGroup === undefined ? '' : purchaseGroup.Subtitle;

                            var grouping = groupedByKey.find(g=> g.Title === title);

                            if(grouping === undefined) {
                                groupedByKey.push({Events:[], Title: title, Subtitle: subtitle});
                            }
                            
                            groupedByKey.find(g => g.Title === title)?.Events.push(e);
                        }
                    }

                    setGroupedEvents(groupedByKey);
                    setTournament(tournament);
                }                
            }
        }

        fetchData();     
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [inProgress, account, instance]);

    useEffect(()=> {        
        if(tournament !== undefined) {
            if (DateTime.utc() >= DateTime.fromJSDate(tournament.StartDate)) {
                setHasTournamentStarted(true);
            } 
            else {
                setHasTournamentStarted(false);
            }
            
            if (DateTime.utc() < DateTime.fromJSDate(tournament.EndDate)) {
                setHasTournamentEnded(false);
            } 
            else {
                setHasTournamentEnded(true);
            }
            
            setCanRegister(CanRegister(tournament));
            
            updateCanRegisterForAdditionalEvents();
        }
        else {
            setCanRegister(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[tournament]);

    useEffect(()=> { 
        if(tournament === undefined) {
            return;
        }       

        setCanRegister(CanRegister(tournament));

        updateCanRegisterForAdditionalEvents();

        // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[hasTournamentEnded, hasTournamentStarted, tournament]);

    const updateCanRegisterForAdditionalEvents = () => {
        if(tournament === undefined) {
            setCanRegisterForAdditionalEvents(false);
            return;
        }

        var regoEndDate = tournament.RegistrationEnds !== undefined ? tournament.RegistrationEnds : tournament.StartDate;

        if (DateTime.utc() >= DateTime.fromJSDate(regoEndDate)) {
            setCanRegisterForAdditionalEvents(false);
            return;
        }  
        
        if(tournamentRegistrations !== undefined && tournamentRegistrations.length > 0) {
            
            //are we registered for all events with every student in this membership?
            for(let ev of tournament.Events) {
                //ev.Students = all students in the membership. If CanRegister is true, they can register for this event
                for (let s of ev.Students) {

                    if((ev.AllRegisteredStudentIds === null ||
                        ev.AllRegisteredStudentIds.indexOf(s.StudentId) === -1) &&
                        s.CanRegister) {
                            //create pending registration to support this
                            var dummyRego = new TournamentRegistration();
                            dummyRego.TournamentId = tournament.Id;                        
                            setPendingRegistration(dummyRego);
                            setCanRegisterForAdditionalEvents(true);
                            return;
                    }
                }
            }            
        }
        else{
            //create pending registration to support this
            var rego = new TournamentRegistration();
            rego.TournamentId = tournament.Id;                        
            setPendingRegistration(rego);
        }
        
        setCanRegisterForAdditionalEvents(false);            
    }

    const onEventSelectionChanged = (eventId: string, selectedStudents: Student[]) => {

        //remove all instances of this event from the pending registration, then recreate
        var idx : number;
        
        do {
            idx = pendingRegistration!.Events.findIndex(e => e.CurriculumId === eventId);

            if(idx !== -1) {
                pendingRegistration?.Events.splice(idx, 1);
            }

        } while(idx !== -1)

        for(let d of selectedStudents) {
            pendingRegistration!.Events.push({
                CurriculumId: eventId,
                SubmissionId: '',
                Created: new Date(),
                Id: '',
                Role: TournamentRole.Participant,
                StudentId: d.Id,
                Status: RegistrationStatus.Pending
            });
        }
        
        setPendingRegistration({...pendingRegistration!});
    }

    const getPurchasedCatalogItems = (catalogId: string) : ITournamentCatalogItemPurchase[] => {
        
        var toReturn : ITournamentCatalogItemPurchase[] = [];

        if(tournamentRegistrations !== undefined) {
            for(let reg of tournamentRegistrations) {
                if(reg.Status === TournamentRegistrationStatus.Complete) {
                    var foundCatalogItems = reg.Catalog.filter(c=>c.CatalogItemId === catalogId);
        
                    if(foundCatalogItems.length > 0) {
                        toReturn.push(...foundCatalogItems);
                    }
                }
            }
        }        

        return toReturn;
    }

    const getRegisteredStudentsForEvent = (event: TournamentEvent) : Student[] => {
        // if(tournamentRegistration === undefined) {
        //     return students!.filter(s=>event.StudentIds.indexOf(s.Id) !== -1);
        // }
        
        var toReturn: Student[] = [];

        for(let s of event.AllRegisteredStudentIds) {            
            let studentId = s;

            if(event.Students.find(eId => eId.StudentId === studentId) !== undefined) {
                var foundStudent = tournament?.Students?.find(a=>a.Id === studentId);
                
                if(foundStudent !== undefined) {
                    toReturn.push(foundStudent);
                }
            }
        }

        return toReturn;
    }

    const getNotRegisteredStudentsForEvent = (event: TournamentEvent) : IStudentWithRegistrationPermission[] => {
        var registered = getRegisteredStudentsForEvent(event);
         
        var toReturn : IStudentWithRegistrationPermission[] = [];

        if (students !== undefined) {
            for (let s of students) {
                let studentId = s.Id;
                if (registered.find(r => r.Id === studentId) === undefined &&
                    event.Students.find(i => i.StudentId === studentId)) {
                        toReturn.push({
                            Student: s,
                            CanRegister: event.Students.find(a => a.StudentId === studentId)!.CanRegister
                        });
                }
            }
        }

        return toReturn;
    }

    const EndTimer = () => 
        <Stack>
            <Stack.Item align='center'>
                <Text variant="xLarge" style={{fontVariant:'small-caps'}}>{tournament?.Title} Ends</Text>
            </Stack.Item>

            <Stack.Item align='center'>
                <CountdownTimer showDays showHours showMinutes showSeconds targetDate={tournament?.EndDate!} timezone={tournament?.Timezone} completed={()=>setHasTournamentEnded(true)}/>
            </Stack.Item>                                
        </Stack>

    const StartTimer = () => 
        <Stack>
            <Stack.Item align='center'>
                <Text variant="xLarge" style={{fontVariant:'small-caps'}}>{tournament?.Title} Starts</Text>
            </Stack.Item>

            <Stack.Item align='center'>
                <CountdownTimer showDays showHours showMinutes showSeconds targetDate={tournament?.StartDate!} timezone={tournament?.Timezone} completed={()=>setHasTournamentStarted(true)}/>
            </Stack.Item>                                
        </Stack>


const WatchMode = () : JSX.Element => {
    return <TournamentViewer tournament={tournament!} />
    }
    
    const getPricingStrategy = (event: TournamentEvent): IPricingStrategy => {
        if(tournament === undefined) {
            throw new Error("Tournament was not found");
        }
    
        var pricing = tournament.Pricing.find(p=> p.CurriculumId === event.EventId)!;
        
        if (pricing.PricingStrategyId === null) {
            return { Expiry: new Date(), Id: '', SubsequentPrice: pricing.Price, Title: '', InitialPrice: pricing.Price };
        }
        
        var pricingStrategy = tournament.PricingStrategies.find(p=>p.Id === pricing.PricingStrategyId);    
    
        if (pricingStrategy === undefined) {
            throw new Error("Pricing model invalid");
        }
    
        return pricingStrategy;
    }

const clearItems = (catId: string) : ITournamentCatalogItemPurchase[] => {
    if(pendingRegistration === undefined) {
        return [];   
    }

    var foundItems = pendingRegistration.Catalog.filter(c=>c.CatalogItemId === catId);

    if(foundItems.length > 0) {
        for(let i of foundItems) {
            var idx = pendingRegistration.Catalog.indexOf(i);
            pendingRegistration.Catalog.splice(idx, 1);
        }        
    }

    return pendingRegistration.Catalog;
}

const onCatalogItemsCleared = (catalogItemId: string) => {
    if(pendingRegistration === undefined) {
        return;
    }

    var catalogItems = clearItems(catalogItemId);
    pendingRegistration!.Catalog = JSON.parse(JSON.stringify(catalogItems));
    setPendingRegistration(() => ({...pendingRegistration}));
}

const onCatalogItemsCommitted = (items: ITournamentCatalogItemPurchase[]) => {
    if(pendingRegistration === undefined) {
        return;
    }

    for(let item of items) {
        clearItems(item.CatalogItemId);
    }

    var catalogItems : ITournamentCatalogItemPurchase[] = [];

    //store existing items
    for(let existing of pendingRegistration.Catalog) {
        catalogItems.push(existing);
    }

    for(let item2 of items) {
        catalogItems.push({
            CatalogItemId: item2.CatalogItemId,
            Qty: item2.Qty,
            Config: item2.Config, 
            Status: item2.Status,
            Created: item2.Created
        });
    }
    
    pendingRegistration!.Catalog = JSON.parse(JSON.stringify(catalogItems));
    setPendingRegistration(() => ({...pendingRegistration}));
}

const CatalogListing = () : JSX.Element => {
    return (tournament === undefined ? <Loader Text='Just a moment...' /> : 
        <Stack horizontal style={{marginLeft:'auto', marginRight:'auto', minWidth:isMobile ? 0 : 600, width: isMobile ? '100%' : 'auto'}} tokens={{childrenGap:isMobile ? 5 : 10}} wrap>
            {tournament.Catalog.map((c,idx)=>
                    <CatalogItemCard 
                        key={idx}
                        availableQuantity={c.AvailableQuantity}
                        isCompact={tournament.Layout === TournamentLayout.Compact}
                        catalogItemId={c.Id}
                        onItemsCleared={onCatalogItemsCleared}
                        onItemsCommitted={(items: ITournamentCatalogItemPurchase[]) => onCatalogItemsCommitted(items)}
                        description={c.Description}
                        imageUri={c.ImageUri}
                        inputs={c.Inputs}
                        name={c.Name}
                        purchasedItems={getPurchasedCatalogItems(c.Id)}
                        price={c.Price} />                        
            )}
        </Stack>)
}

const RegisterMode = () : JSX.Element => {
    return (canRegister ? 
        <Stack tokens={{childrenGap:10}}>
            <>
                {hasTournamentStarted ? 
                        hasTournamentEnded ? 
                            null : 
                            <EndTimer /> : 
                            <StartTimer />
                }
            </>  
            {tournament?.RegistrationDescription !== null && tournament!.RegistrationDescription.length > 0 ? 
                <Stack horizontalAlign='center'><br /><div style={{maxWidth:600, textAlign:'center'}} dangerouslySetInnerHTML={{__html:tournament!.RegistrationDescription}} /></Stack> : 
                null
            }

            {tournament?.Layout === TournamentLayout.Compact ?
                
                <div style={{marginLeft:'auto', marginRight:'auto', minWidth: isMobile ? 0 : 600}}>
                    {groupedEvents?.map((group, groupIdx)=>                 
                        <Stack key={groupIdx} tokens={{childrenGap:5}}>
                            <br />
                            <Text style={{ fontWeight: 'bold', paddingBottom: 5 }} key={groupIdx} variant='medium'>{group.Title}</Text>
                            {group.Subtitle.length > 0 && <Text variant='small'>{group.Subtitle}</Text>}
                            <Stack tokens={{childrenGap:7}}>
                            {group.Events.map((event,idx) => 
                                    <TournamentEventCard 
                                        status={event.Status}
                                        isCompact={tournament?.Layout === TournamentLayout.Compact}
                                        displayMode={EventCardDisplayMode.Students} 
                                        key={idx} 
                                        pricingStrategy={getPricingStrategy(event)}
                                        additionalFee={tournament!.Pricing.find(p=>p.CurriculumId === event.EventId)!.AdditionalFee}
                                        eventId={tournament!.Events.find(te=>te.EventId === event.EventId)!.EventId}
                                        eventDescription={tournament!.Events.find(te=>te.EventId === event.EventId)!.Description}
                                        eventImageUri={tournament!.Events.find(te=>te.EventId === event.EventId)!.ImageUri}
                                        eventTitle={tournament!.Events.find(te=>te.EventId === event.EventId)!.Title} 
                                        onViewEventClicked={(e)=>navigate(`/event/${tournament!.Id}/item/${event.EventId}`)}
                                        onEventSelectionChanged={onEventSelectionChanged}
                                        notRegisteredStudents={getNotRegisteredStudentsForEvent(event)}
                                        registeredStudents={getRegisteredStudentsForEvent(event)}
                                    />                      
                            )}
                            </Stack>
                        </Stack>               
                    )}
                </div> 
                 :
                <Stack tokens={{childrenGap:10}}>                                            
                    {groupedEvents?.map((group, groupIdx)=> <span style={{paddingTop:20}} key={groupIdx}>
                        <Text variant='large'>{group.Title}</Text>
                        {group.Subtitle.length > 0 && <><br /><Text variant='small'>{group.Subtitle}</Text></>}
                                <br /><br />
                                {group.Title.length > 0 ? <span style={{width:30, marginTop:5, borderBottom:'3px solid rgba(0, 120, 212, 255)'}}></span> : null}
                                <Stack tokens={{childrenGap:10}} horizontal wrap>
                                    {group.Events.map((event,idx) => 
                                            <TournamentEventCard 
                                                displayMode={EventCardDisplayMode.Students} 
                                                key={idx} 
                                                pricingStrategy={getPricingStrategy(event)}
                                                additionalFee={tournament!.Pricing.find(p=>p.CurriculumId === event.EventId)!.AdditionalFee}
                                                eventId={tournament!.Events.find(te=>te.EventId === event.EventId)!.EventId}
                                                eventDescription={tournament!.Events.find(te=>te.EventId === event.EventId)!.Description}
                                                eventImageUri={tournament!.Events.find(te=>te.EventId === event.EventId)!.ImageUri}
                                                eventTitle={tournament!.Events.find(te=>te.EventId === event.EventId)!.Title} 
                                                onViewEventClicked={(e)=>navigate(`/event/${tournament!.Id}/item/${event.EventId}`)}
                                                onEventSelectionChanged={onEventSelectionChanged}
                                                notRegisteredStudents={getNotRegisteredStudentsForEvent(event)}
                                                registeredStudents={getRegisteredStudentsForEvent(event)}
                                            />                      
                                    )}
                                </Stack>
                            </span>                                                            
                    )}           
                </Stack>
            }
            {tournament !== undefined && tournament.Catalog.length > 0 ? 
                <Stack style={{marginLeft:'auto', marginRight:'auto', minWidth:isMobile ? 0 : 600, width: isMobile ? '100%' : 'auto'}} tokens={{childrenGap:isMobile ? 5 : 10}}>
                    <br />
                    {tournament.Layout === TournamentLayout.Compact ? 
                        <Text style={{fontWeight:'bold', paddingBottom:5}} variant='medium'>Additional items</Text> :
                        <Text variant='large'>Additional items</Text>
                    }
                    <CatalogListing />
                </Stack> : null
            }
        </Stack> :
        hasTournamentEnded ?        
            tournament?.Type === TournamentType.Online ? 
                <Stack>
                    <Text variant='xLarge'>{tournament?.Title}</Text>
                    <WatchMode />
                </Stack>
                 : 
                <Stack>
                    <Text variant='xLarge'>Tournament Ended</Text>
                    <Text>{tournament?.Title} has ended.</Text>
                </Stack>
        
        :     
        <>
        <Stack tokens={{childrenGap:10}}>
        {tournament?.Type === TournamentType.InPerson ? null :
            <>
                <Text variant='xLarge'>Get Ready</Text>
                <Text>Practice now to get ready to enter. View each event for details on how to prepare.</Text>
            </>
        }
        </Stack> 
        {tournament?.Layout === TournamentLayout.Compact || (tournamentRegistrations === undefined || tournamentRegistrations?.length === 0) ? 
            <Stack tokens={{childrenGap:40}}>
                {hasTournamentStarted ? 
                        hasTournamentEnded ? 
                            null : 
                            <EndTimer /> : 
                            <StartTimer />
                }                
                <Text style={{color:'red', textAlign:'center'}} variant='mediumPlus'>Registration has closed</Text>                                 

                <EventRegistrations tournaments={[tournament!]} reviews={[]} registrations={tournamentRegistrations!}  />
            </Stack>  
             :   
             <EnterMode />
        }
        </>
    )
}

const navToRegisterMode = () => {
    navigate(`/event/${tournament!.Id}/register`);    
    setDashboardMode(Mode.Register);
}

const getEventsForStudent = (studentId: string) : ITournamentEventRegistration[] => {
    var toReturn : ITournamentEventRegistration[] = [];
        
    if(tournamentRegistrations === undefined) {
        return toReturn;
    }

    for(let t of tournamentRegistrations) {
        var eventsForStudent = t.Events.filter(e => e.StudentId === studentId);

        if(eventsForStudent.length > 0) {
            toReturn.push(...eventsForStudent);
        }
    }

    return toReturn;
}
const EnterMode = () : JSX.Element => {
        return <Stack tokens={{childrenGap:10}}>  
                <>
                {hasTournamentStarted ? 
                        hasTournamentEnded ? 
                            null : 
                            <EndTimer /> : 
                            <StartTimer />
                }
                </>                                        
            {hasTournamentEnded ? 
                null :
                <Stack tokens={{childrenGap:20}}>
                    {canRegisterForAdditionalEvents ? <Stack style={{marginTop:10}} horizontal horizontalAlign='end'><DefaultButton iconProps={{iconName:'AddTo'}} onClick={()=> navToRegisterMode()}>Add events</DefaultButton></Stack> : null}
                    {tournament!.Type === TournamentType.InPerson ? 
                        <Stack tokens={{childrenGap:10}}>
                            <Text variant='xLarge'>Registered Events</Text>
                            <Text>These are the events that each athlete has registered for.</Text>
                        </Stack>  :
                        <Stack tokens={{childrenGap:10}}>
                            <Text variant='xLarge'>Enter events</Text>
                            <Text>Enter all events before the tournament ends. View each event for details on how to enter.</Text>                            
                        </Stack> 
                    }
                    <Pivot overflowBehavior='menu'>
                        {students?.map((s,idx) => 
                            <PivotItem key={idx} itemKey={idx.toString()} headerText={s.FirstName}>
                                <br />
                                <Stack tokens={{childrenGap:20}} horizontal wrap>                                            
                                    {getEventsForStudent(s.Id).map((e,eventIdx) => 
                                        <TournamentEventCard 
                                            displayMode={tournamentRegistrations === undefined || tournamentRegistrations.length === 0 ? EventCardDisplayMode.Students : EventCardDisplayMode.Entry} 
                                            key={eventIdx}                                             
                                            eventId={tournament!.Events.find(te=>te.EventId === e.CurriculumId)!.EventId}
                                            eventDescription={tournament!.Events.find(te=>te.EventId === e.CurriculumId)!.Description}
                                            eventImageUri={tournament!.Events.find(te=>te.EventId === e.CurriculumId)!.ImageUri}
                                            eventTitle={tournament!.Events.find(te=>te.EventId === e.CurriculumId)!.Title} 
                                            onViewEventClicked={(eId)=>navigate(`/student/${s.Id}/curriculum/${eId}/tournament/${tournament!.Id}`)}
                                            onEventSelectionChanged={onEventSelectionChanged}
                                        />  
                                    )}            
                                </Stack>                        
                            </PivotItem>
                        )}    
                    </Pivot>                                 
                </Stack>
            }                

            <br />
            {hasTournamentStarted && tournament !== undefined && tournament.Type === TournamentType.Online ? <Leaderboard title="Leaderboard" tournament={tournament} hasTournamentStarted={hasTournamentStarted} /> : null}
        </Stack>        
}

    const renderContent = React.useCallback(() => {
        switch(dashboardMode) {
            case Mode.Watch: 
                return <WatchMode />
            case Mode.Enter:
                return <EnterMode />
            default: 
                return <RegisterMode />;
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [dashboardMode, tournament, tournamentRegistrations, hasTournamentEnded, hasTournamentStarted, canRegister, canRegisterForAdditionalEvents, students]);
    
    const hasPrerequisites = (
        prereq: IPurchasePrerequisite,
        pendingRegistration: ITournamentRegistration,
        studentId: string): boolean => {
        var hasPrerequisites = true;
        
        switch (prereq.Type) {
            case PurchasePrerequisiteType.All:
                {
                    if (prereq.CatalogIds.length > 0) {
                        var foundCatalogAll = pendingRegistration.Catalog.find(c => prereq.CatalogIds.indexOf(c.CatalogItemId) !== -1);
            
                        var alreadyPurchasedCatalogAll = tournamentRegistrations?.find(r => r.Catalog.find(c => prereq.CatalogIds.indexOf(c.CatalogItemId) !== -1 && c.Status === RegistrationStatus.Complete) !== undefined);
            
                        if (foundCatalogAll === undefined ||
                            alreadyPurchasedCatalogAll === undefined) {
                            hasPrerequisites = false;
                        }
                    }
            
                    if (prereq.EventIds.length > 0) {
                        var foundPrereqEventAll = pendingRegistration.Events.find(e => prereq.EventIds.indexOf(e.CurriculumId) !== -1 && e.StudentId === studentId);
            
                        var alreadyPurchasedEventsAll = tournamentRegistrations?.find(r => r.Events.find(e => prereq.EventIds.indexOf(e.CurriculumId) !== -1 && e.StudentId === studentId && e.Status === RegistrationStatus.Complete) !== undefined);
            
                        if (foundPrereqEventAll === undefined ||
                            alreadyPurchasedEventsAll === undefined) {
                            hasPrerequisites = false;
                        }
                    }
                    break;
                }
            case PurchasePrerequisiteType.Any:
                {
                    var hasAny = false;

                    var foundCatalog = pendingRegistration.Catalog.find(c => prereq.CatalogIds.indexOf(c.CatalogItemId) !== -1);
                    var alreadyPurchasedCatalog = tournamentRegistrations?.find(r => r.Catalog.find(c => prereq.CatalogIds.indexOf(c.CatalogItemId) !== -1 && c.Status === RegistrationStatus.Complete) !== undefined);

                    if (foundCatalog !== undefined ||
                        alreadyPurchasedCatalog !== undefined) {
                        hasAny = true;
                    }
                    
                    var foundPrereqEvent = pendingRegistration.Events.find(e => prereq.EventIds.indexOf(e.CurriculumId) !== -1 && e.StudentId === studentId);
                    var alreadyPurchasedEvents = tournamentRegistrations?.find(r => r.Events.find(e => prereq.EventIds.indexOf(e.CurriculumId) !== -1 && e.StudentId === studentId && e.Status === RegistrationStatus.Complete) !== undefined);
                    
                    if (foundPrereqEvent !== undefined ||
                        alreadyPurchasedEvents !== undefined) {
                        hasAny = true;
                    }
                    
                    if (!hasAny) {
                        hasPrerequisites = false;
                    }
                    break;
                }
        }
        
        return hasPrerequisites;
    }

    const isMissingPrerequisites = () : boolean => {

        if(pendingRegistration === undefined) {
            return false;
        }

        //check if we have any events that are missing prerequisites
        for(let event of pendingRegistration.Events) {
            var foundEvent = tournament?.Events.find(e=>e.EventId === event.CurriculumId);
            var isPrereqMet = true;

            if (foundEvent !== undefined &&
                foundEvent.Prerequisites !== null &&
                foundEvent.Prerequisites.length > 0) {
                
                for (let prereq of foundEvent.Prerequisites) {
                    isPrereqMet = hasPrerequisites(prereq, pendingRegistration, event.StudentId);
                    
                    if (!isPrereqMet) {
                        return true;
                    }
                }
            }            
        }

        return false;
    }

    interface IStudentPurchaseGrouping {
        PurchaseGroupId: string;        
        StudentId: string;
        Events: string[];
    }

    interface IInvalidPurchaseGroupsResult {
        Events: string[];
        MaxEventsForStudent: number;
    }

    const getInvalidPurchaseGroups = (): IInvalidPurchaseGroupsResult => {
        //check that each purchase group limitation has not been exceeded for each event and student combination,
        //ie: each student and event combination can only purchase up to MaxItemsPerParticipant for each item
        
        if (pendingRegistration === undefined) {
            return { Events: [], MaxEventsForStudent: -1 }; 
        }

        let eventsAndStudentsInGroup : IStudentPurchaseGrouping[] = [];

        for (let event of pendingRegistration.Events) {
            let eventWithDivision = tournament?.Events.find(e => e.EventId === event.CurriculumId);
            
            let purchaseGroup = tournament?.PurchaseGroups.find(p => p.Id === eventWithDivision?.PurchaseGroupId);

            if (purchaseGroup === undefined ||
                purchaseGroup.MaxItemsPerParticipant === null ||
                purchaseGroup.MaxItemsPerParticipant === undefined) {
                continue;
            }

            let storedGroupForStudent = eventsAndStudentsInGroup.find(e => e.PurchaseGroupId === purchaseGroup!.Id && e.StudentId === event.StudentId);
            
            let completedRegistrationsForStudent = tournamentRegistrations?.find(r => r.Events.find(e => e.StudentId === event.StudentId && e.Status !== RegistrationStatus.Pending) !== undefined);
            
            let alreadyPurchasedEventsInPurchaseGroup : string[] = [];

            //find all events where the event has the same purchase group id and the student has already purchased the event
            if (completedRegistrationsForStudent !== undefined) {
                            
                for (let ape of completedRegistrationsForStudent.Events) {
                    if (ape.Status !== RegistrationStatus.Complete) {
                        continue;
                    }

                    let apepg = tournament?.Events.find(ev => ev.EventId === ape.CurriculumId)!.PurchaseGroupId;
                
                    if (apepg !== purchaseGroup.Id) {
                        continue;
                    }

                    if (ape.StudentId !== event.StudentId) {
                        continue;
                    }

                    alreadyPurchasedEventsInPurchaseGroup.push(ape.CurriculumId);
                }
            }

            if (storedGroupForStudent !== undefined) {
                //store the event for this student
                storedGroupForStudent.Events.push(event.CurriculumId);                
            }
            else {
                storedGroupForStudent = { PurchaseGroupId: purchaseGroup.Id, StudentId: event.StudentId, Events: [event.CurriculumId] };
                
                eventsAndStudentsInGroup.push(storedGroupForStudent);
            }

            if (alreadyPurchasedEventsInPurchaseGroup !== undefined) {
                for (let ap of alreadyPurchasedEventsInPurchaseGroup) {
                    if (storedGroupForStudent!.Events.indexOf(ap) === -1) {
                        storedGroupForStudent!.Events.push(ap);
                    }
                }
            }

            if (storedGroupForStudent!.Events.length > purchaseGroup.MaxItemsPerParticipant) {
                return {
                    Events: storedGroupForStudent!.Events.map(e => tournament!.Events.find(ev => ev.EventId === e)!.Title),
                    MaxEventsForStudent: purchaseGroup.MaxItemsPerParticipant
                }
            }
        }

        return { Events: [], MaxEventsForStudent: -1 };
    }

    const onProceedToCheckout = () => {
        if (isMissingPrerequisites()) {
            alert('Prerequisites items for the selected events are missing.');
            return;
        }

        let invalidPurchaseGroups = getInvalidPurchaseGroups();
        if (invalidPurchaseGroups.Events.length > 0) {
            alert(`You have exceeded the maximum number of items that can be purchased. A maximum of ${invalidPurchaseGroups.MaxEventsForStudent} events per student may be purchased from the group: ${invalidPurchaseGroups.Events.join(', ')}`);
            return;
        }

        if(pendingRegistration === undefined || (pendingRegistration.Events.length === 0 && pendingRegistration.Catalog.length === 0)){
            toggleHideDialog();
        }
        else{
            openCompleteRegistration();
        }
    }

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const dialogStyles = { main: { maxWidth: 450 } };   
   
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Empty Cart',
        closeButtonAriaLabel: 'Close',
        subText: 'Please select one or more items before proceeding to the checkout.',
    };
    
    return (
        <div style={{padding: isMobile ? 5 : 20}}>
        {tournament === undefined ? 
            <Loader Text="Just a moment..." /> : 
            <Stack tokens={{childrenGap:15}} verticalAlign='start'>                
                {renderContent()}
                {((tournamentRegistrations === undefined || tournamentRegistrations.length === 0) && canRegister && dashboardMode !== Mode.Watch) || (canRegisterForAdditionalEvents && dashboardMode !== Mode.Watch) ? 
                <>
                    <Stack horizontalAlign='center' tokens={{childrenGap:10}}>     
                        <br />
                        <PrimaryButton onClick={()=>onProceedToCheckout()} style={{minWidth:200}}>Proceed to Checkout</PrimaryButton>
                    </Stack>
                    <Panel
                        headerText={tournament!.Title}
                        isOpen={isCompleteRegistrationOpen}
                        onDismiss={dismissCompleteRegistration}
                        closeButtonAriaLabel="Close">
                        <RegisterTournament stripePromise={stripePromise} students={students!} tournament={tournament!} pendingRegistration={pendingRegistration!} />
                    </Panel>
                </> : 
                null}
            </Stack>
        }
        <Dialog
            hidden={hideDialog}
            onDismiss={toggleHideDialog}
            dialogContentProps={dialogContentProps}
            modalProps={{styles:dialogStyles}}>
            <DialogFooter>
                <PrimaryButton onClick={toggleHideDialog} text="Ok" />
            </DialogFooter>
        </Dialog>
        </div>
    );
}

export default TournamentDashboard;