export interface ITrainingFacility {    
    Id: string;
    Title: string;
    Location: string;
}

export class TrainingFacility implements ITrainingFacility {
    Id: string;
    Title: string;
    Location: string;

    constructor(
        params: ITrainingFacility = {} as ITrainingFacility) {
            
        let {
            Id = "",
            Title = "",
            Location = ""
        } = params;
            
        this.Id = Id;
        this.Title = Title;
        this.Location = Location;
    }
}