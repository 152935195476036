import { Dropdown, IDropdownOption, Label, Stack, TextField, Text, SpinButton, Checkbox } from '@fluentui/react';
import React, {FC, useEffect, useState} from 'react';
import { Level } from '../model/Level';
import { Program } from '../model/Program';
import { Gender, Student } from '../model/Student';
import DateEditor from './DateEditor';
import { DateTime } from 'luxon';
import StudentInput, { DisplayMode } from '../pages/Tournament/components/StudentInput';
import { IUserInputWithStudents } from '../model/CatalogItem';

interface IData {
    levels: Level[];
    programs: Program[];
    editStudent?: Student | undefined;
    requestDob?: boolean;
    requestGender?: boolean;
    requestWeight?: boolean;
    customValues?: IUserInputWithStudents[];
    onModelValidationStatusChanged: (student: Student, isValid: boolean)  => void;
}

const StudentEditor : FC<IData> = ({levels, programs, requestDob, requestGender, requestWeight, editStudent, customValues, onModelValidationStatusChanged}) => { 
    const [student, setStudent] = useState<Student>(editStudent === undefined ? new Student() : editStudent);
    const [levelOptions, setLevelOptions] = useState<IDropdownOption[]>([]);
    const [programOptions, setProgramOptions] = useState<IDropdownOption[]>([]);
    const [genderOptions, setGenderOptions] = useState<IDropdownOption[]>([]);
    const [levelDescription, setLevelDescription] = useState<string>();

    const validateFieldNotEmpty = (value: string) : string => {
        if(value.length > 0){
          return '';
        }
  
        return 'Field cannot be empty';
      }

    const checkModelValidationState = () => {        
        if(student.FirstName.length === 0 || student.LastName.length === 0) {
            onModelValidationStatusChanged(student, false);    
            return;
        }

        if(student.ProgramIds.length === 0) {
            onModelValidationStatusChanged(student, false);
            return;
        }

        if(student.LevelId === undefined || student.LevelId.length === 0) {
            onModelValidationStatusChanged(student, false);
            return;
        }                

          if (requestDob &&
              (student.DateOfBirth === undefined ||
                student.DateOfBirth > DateTime.now().toJSDate())) {
              onModelValidationStatusChanged(student, false);
              return;
        }

        if(requestGender && student.Gender === undefined) {
            onModelValidationStatusChanged(student, false);
            return;
        }
        
        onModelValidationStatusChanged(student, true);
      }

      useEffect(()=>{

        if(editStudent === undefined) {
            return;
        }

        loadLevelsForProgram(editStudent.ProgramIds[0]);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps    
      },[editStudent]);

      useEffect(()=> {        
        checkModelValidationState();
        // eslint-disable-next-line react-hooks/exhaustive-deps    
      }, [student]);

      useEffect(()=> {
        var programOptions : IDropdownOption[] = [];

        programs.forEach((p,idx) => {            
            programOptions.push({
                key: p.Id,
                text: p.Name,
                id: p.Id
            });
        });

        setProgramOptions(programOptions);

        var genderOptions : IDropdownOption[] = [];

        const numericGenderValues = Object.values(Gender).filter(x => typeof x === "number");
        const stringGenderValues = Object.values(Gender).filter(x => typeof x === "string");

        numericGenderValues.forEach((g,idx) => {            
            genderOptions.push({
                key: idx,
                text: stringGenderValues[idx].toString(),
                id: g.toString()
            });
        });

        setGenderOptions(genderOptions);

        // eslint-disable-next-line react-hooks/exhaustive-deps    
      },[]);

      const onProgramChanged = (
          event: React.FormEvent<HTMLDivElement>, 
          option?: IDropdownOption<any> | undefined, 
          index?: number | undefined) => {

        var targetProgram = option!.id!;

        setStudent(student => ({...student, LevelId: '', ProgramIds: [targetProgram]})); 
        loadLevelsForProgram(targetProgram);
    }

    const loadLevelsForProgram = (targetProgram: string) => {
        var programLevels: IDropdownOption[] = [];

        levels.forEach((l,idx) => {
            if(l.ProgramIds.indexOf(targetProgram) !== -1) {
                programLevels.push({
                    key: l.Id,
                    text: l.Label,
                    id: l.Id,
                    ariaLabel: l.Description
                }); 
            }           
        });

        setLevelOptions(programLevels);
    }

    const onLevelChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {                
        var targetLevel = option!.id!;        
        
        setStudent(student => ({...student, LevelId: targetLevel}));         
        setLevelDescription(levels.find(l=>l.Id === targetLevel)!.Description);
    }

    const onGenderChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {                
        var targetGender = parseInt(option!.id!);                
        setStudent(student => ({...student, Gender: targetGender as Gender})); 
    }
    
    const onDateSelected = (d:Date) => {      
        setDateOfBirth(d);

        if(d === undefined || d === null) {
            setStudent(student => ({...student, DateOfBirth: undefined}));
            return;
        }          
        
        setStudent(student => ({...student, DateOfBirth: d}));
    }

    const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>(student.DateOfBirth);

    return (
        <>
            <Stack tokens={{childrenGap:10}}>
                <Label>First Name</Label>            
                <TextField 
                    styles={{field:{ fontSize:16}}}
                    inputMode="text"                                               
                    defaultValue={student.FirstName}
                    validateOnLoad={false} 
                    validateOnFocusOut 
                    onChange={(c: React.FormEvent, newValue?: string) => setStudent(student => ({...student, FirstName: newValue!}))} 
                    onGetErrorMessage={validateFieldNotEmpty}/>
                    
                <Label>Last Name</Label>
                <TextField 
                    styles={{field:{ fontSize:16}}}
                    inputMode="text"      
                    defaultValue={student.LastName}                                         
                    validateOnLoad={false} 
                    validateOnFocusOut 
                    onChange={(c: React.FormEvent, newValue?: string) => setStudent(student => ({...student, LastName: newValue!}))} 
                    onGetErrorMessage={validateFieldNotEmpty}/>
                
                {requestGender ? 
                <>                
                    <Label>Gender</Label>
                    <Dropdown styles={{dropdownOptionText:{fontSize:16}, title:{fontSize:16}}} options={genderOptions} onChange={onGenderChanged} defaultSelectedKey={student.Gender} />
                </> : null}

                {requestWeight ? 
                <>                
                    <Label>Weight</Label>
                    <SpinButton 
                        min={0} 
                        max={400} 
                        defaultValue={student.Weight.toString()}
                        onChange={(c: React.FormEvent, newValue?: string) => setStudent(student => ({...student, Weight: parseFloat(newValue!)}))}  />
                </> : null}

                {requestDob ? 
                <>
                    <Label>Date of Birth</Label>
                    <DateEditor 
                        minDate={new Date(1900, 0, 1)}
                        maxDate={DateTime.now().toJSDate()}
                        onDateChange={(date)=>onDateSelected(date)} 
                        date={dateOfBirth === undefined ? undefined : dateOfBirth} />
                        
                </> : null}

                <Label>Program</Label>
                <Dropdown styles={{dropdownOptionText:{fontSize:16}, title:{fontSize:16}}} options={programOptions} onChange={onProgramChanged} defaultSelectedKey={student.ProgramIds[0]} />

                <Label>Level</Label>
                <Dropdown 
                    styles={{dropdownOptionText:{fontSize:16}, title:{fontSize:16}}}
                    options={levelOptions}                    
                    disabled={student.ProgramIds.length === 0} 
                    onChange={onLevelChanged} 
                    defaultSelectedKey={student.LevelId}/>

                {levelDescription === undefined ? null : <Text variant='smallPlus'>{levelDescription}</Text>}

                <Label>Special Needs</Label>
                <Checkbox label='This individual requires additional accommodation due to physical or cognitive differences' onChange={(ev, checked) => setStudent(student => ({ ...student, SpecialNeeds: checked! }))} />

                {customValues?.map((cv, idx) => <span key={idx}>
                    <StudentInput
                        input={cv}
                        displayMode={DisplayMode.LabelVertical}
                        student={student}
                        tournamentId={cv.TournamentId}
                        onValidationChanged={(isValid: boolean) => onModelValidationStatusChanged(student, isValid)}
                        required={cv.IsRequired} />
                </span>)}
            </Stack>

        </>
    )
}

export default StudentEditor;