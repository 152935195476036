import { ActionButton, Image, ImageFit, PrimaryButton, Stack, Text } from '@fluentui/react';
import {FC, useEffect, useState} from 'react';
import { getTournamentLandingPageContent } from '../../ApiService';
import CountdownTimer from '../../components/CountdownTimer';
import TournamentEventCard, { EventCardDisplayMode } from '../Tournament/components/TournamentEventCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { DateTime } from 'luxon';
import { TournamentLayout, TournamentType } from '../../model/Tournament';
import { TournamentLandingPageViewModel } from '../../model/TournamentLandingPageViewModel';
import { GetTournamentStartAndEndDates } from '../../Display';

const TournamentLandingPage : FC = () => {
    let { tournamentId } = useParams<{tournamentId?: string }>() as {tournamentId?: string };
    const navigate = useNavigate();
    const [landingPageViewModel, setLandingPageViewModel] = useState<TournamentLandingPageViewModel>();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    const [hasRegistrationClosed, setHasRegistrationClosed] = useState<boolean>(false);
    const [hasStarted, setHasStarted] = useState<boolean>(false);
    const [hasEnded, setHasEnded] = useState<boolean>(false);

    const [errorCode, setErrorCode] = useState<number>();

    useEffect(()=> {
      if(errorCode === undefined) {
        return;
      }

      throw new Error("The server returned status code: " + errorCode);
    },[errorCode]);

    useEffect(()=>{
        const fetchData = async () => {
            var vm = await getTournamentLandingPageContent(tournamentId!);

            if(typeof vm === 'number') {
                setErrorCode(vm);
                return;
            }
            
            document.title = `${vm.Tournament?.Title} on NinjaPanel`;

            if(vm?.CanJoin){
                //are we already authenticated? just go to tournament page if yes
                if(isAuthenticated && tournamentId !== undefined) {                    
                    navigate(`/event/${tournamentId}`);
                    return;
                }

                setHasRegistrationClosed(DateTime.utc() >= DateTime.fromJSDate(vm.Tournament.RegistrationEndDate));

                if (vm.Tournament?.StartDate !== undefined) {                    
                    setHasStarted(DateTime.utc() >= DateTime.fromJSDate(vm.Tournament.StartDate));
                }
                
                if(vm.Tournament?.EndDate !== undefined) {
                    setHasEnded(DateTime.utc() >= DateTime.fromJSDate(vm.Tournament.EndDate));
                }

                setLandingPageViewModel(vm);
            }
            else {
                doLogin();
            }
        }

        fetchData();     
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [isAuthenticated, tournamentId]);

    const doLogin = () => {
        instance.loginRedirect({ scopes:['openid profile','offline_access'], state: document.location.href});
    }    

    return (
        <div style={{padding:20}}>
            {landingPageViewModel === undefined ? null : 
                <>
                    <Stack className='landing-header' tokens={{childrenGap:20}} horizontal verticalAlign='center' horizontalAlign='end'>
                        <ActionButton onClick={()=>navigate(`/login?to=${document.location.pathname.replace('events', 'event')}`)} styles={{textContainer:{fontSize:20}}}>Log In</ActionButton>
                        <PrimaryButton onClick={()=>navigate('/join')}  style={{height:40}} styles={{textContainer:{fontSize:16, padding:5}}}>Create free account</PrimaryButton>
                    </Stack>
                    <Stack horizontalAlign='center' tokens={{childrenGap:20}}>
                        <br /><Image src={landingPageViewModel?.Tournament.ImageUri} width={165} imageFit={ImageFit.contain}/>
                        <div style={{textAlign:'center'}}>
                            <Text variant='xLarge'>{landingPageViewModel?.Tournament.Title}</Text>
                            <br />                            
                            <Text variant='mediumPlus'>{GetTournamentStartAndEndDates(landingPageViewModel?.Tournament.StartDate!, landingPageViewModel?.Tournament.EndDate!, landingPageViewModel?.Tournament.Timezone)}</Text>
                            {landingPageViewModel.Tournament.Type === TournamentType.InPerson ? <><br /><Text variant='small'>{landingPageViewModel.Tournament.Location}</Text></> : null}
                            
                            
                        </div>                                            
                    
                    <p style={{textAlign:'center', maxWidth:700}} dangerouslySetInnerHTML={{__html:landingPageViewModel.Tournament.Description}}></p>

                    <Stack horizontalAlign='center' horizontal tokens={{childrenGap:20}}>                
                        <Stack>
                        {hasStarted ? 
                                hasEnded ? 
                                    null : 
                                        (<>
                                        <Stack.Item align='center'>
                                            <Text variant="medium" style={{fontVariant:'small-caps'}}>Ends In</Text>
                                        </Stack.Item>

                                        <Stack.Item align='center'>
                                            <CountdownTimer showDays showHours showMinutes showSeconds targetDate={landingPageViewModel.Tournament?.EndDate!} completed={()=>setHasEnded(true)}/>
                                        </Stack.Item>                                
                                        </>)
                                : 
                                    <>
                                    <Stack.Item align='center'>
                                        {hasRegistrationClosed && !hasStarted ? <Text variant='medium' style={{ color: 'red' }}>Registration has closed</Text> : null}
                                    </Stack.Item>
                                    <br />
                                <Stack.Item align='center'>
                                    <Text variant="medium" style={{ fontVariant: 'small-caps' }}>{hasRegistrationClosed ? "Starts In" : "Registration Ends"}</Text>
                                </Stack.Item>
                                <Stack.Item align='center'>
                                            {hasRegistrationClosed ? null :
                                                <CountdownTimer
                                                    showDays
                                                    showHours
                                                    showMinutes
                                                    showSeconds
                                                    targetDate={landingPageViewModel.Tournament.RegistrationEndDate}
                                                    completed={() => setHasRegistrationClosed(true)} />}                                        
                                        {hasStarted || !hasRegistrationClosed ? null :
                                                <CountdownTimer
                                                    showDays
                                                    showHours
                                                    showMinutes
                                                    showSeconds
                                                    targetDate={landingPageViewModel.Tournament.StartDate!}
                                                    completed={() => setHasStarted(true)} />}
                                </Stack.Item>                                
                                </>}
                        </Stack>
                    </Stack>
                    <br />
                    {landingPageViewModel.Tournament.Layout === TournamentLayout.Default ?
                     <Stack tokens={{childrenGap:10}} horizontal wrap horizontalAlign='center'>
                        {landingPageViewModel.Events.map((e,idx) => {
                        return <TournamentEventCard 
                                    key={idx}
                                    eventId=""
                                    eventTitle={e.Title}
                                    eventImageUri={e.ImageUri}
                                    eventDescription=""
                                    displayMode={EventCardDisplayMode.Preview} 
                                    onEventSelectionChanged={()=>{}} 
                                    onViewEventClicked={()=>{}} /> 
                        })}
                        </Stack> : null 
                    }
                    
                        <div style={{marginLeft:'auto', marginRight:'auto', backgroundColor:'white', paddingRight:40, paddingLeft:40, paddingBottom:20}}>
                            <Stack tokens={{childrenGap:10}}>
                                <br />
                                <PrimaryButton onClick={()=>navigate('/join')}  style={{height:40}} styles={{textContainer:{fontSize:20, padding:5}}}>Create free account</PrimaryButton>
                                <ActionButton onClick={()=>navigate(`/login?to=${document.location.pathname.replace('events', 'event')}`)}>Already have a NinjaPanel account? Log In here</ActionButton>
                            </Stack>
                        </div>
                    </Stack>
                </>
        }
        </div>
    );
}

export default TournamentLandingPage;