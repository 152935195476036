import { useAccount, useMsal } from '@azure/msal-react';
import { Dialog, DialogFooter, DialogType, Label, PrimaryButton, Stack, Text } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { writeInputs } from '../../../ApiService';
import { IMembershipInput, IUserInput } from '../../../model/CatalogItem';
import { Student } from '../../../model/Student';
import { Tournament } from '../../../model/Tournament';
import { TournamentRegistration } from '../../../model/TournamentRegistration';
import StudentInput from './StudentInput';
import { useBoolean } from '@fluentui/react-hooks';

export interface IData {
    pendingRegistration: TournamentRegistration;
    tournament: Tournament;
    students: Student[];

    onComplete: () => void;
}

interface IValidationState {
    IsValid: boolean;
    ErrorMessage?: string;
    UserInput: IUserInput;
    Student: Student;
}

const EventInputCollector : FC<IData> = ({onComplete, students, pendingRegistration, tournament}) => {    
    const { instance, accounts} = useMsal();
    const account = useAccount(accounts[0] || {});  
    
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(()=> {
      if(errorMessage === undefined) {
        return;
      }

      throw new Error(errorMessage);
    }, [errorMessage]);
    
    useEffect(() => {
        //checks to see if this view is required or can be skipped
        var hasAtLeastOneRequiredInput = false;

        for(let e of pendingRegistration.Events) {
            let curriculumId = e.CurriculumId;
            var event = tournament.Events.find(t=>t.EventId === curriculumId);
            
            if(event!.Inputs !== null){
                hasAtLeastOneRequiredInput = true;
            }                         
        }     
        
        if(!hasAtLeastOneRequiredInput) {
            onComplete();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[students, pendingRegistration, tournament]);

    const getStudentsForInput = (input: IUserInput) : Student[] => {
        
        //find students that are planning to register for this event
        var eventsForInput = tournament.Events.filter(e=>e.Inputs.find(i=>i.Label === input.Label) !== undefined);
        var toReturn : Student[] = [];

        for(let p of pendingRegistration.Events) {
            let curriculumId = p.CurriculumId;

            if(eventsForInput.find(e=>e.EventId === curriculumId) !== undefined) {
                let studentId = p.StudentId;
                var student = students.find(s => s.Id === studentId);

                if(student !== undefined && toReturn.find(t=>t.Id === studentId) === undefined) {
                    toReturn.push(student);
                }
            }
        }
        return toReturn;
    }

    const getInputs = () : IUserInput[] => {
        var eventsWithInputs = tournament.Events.filter(e=> 
            e.Inputs?.length > 0 && 
            pendingRegistration.Events.find(pEv => pEv.CurriculumId === e.EventId));
        
        var toReturn : IUserInput[] = [];

        for(let e of eventsWithInputs) {            
            let inputs = e.Inputs;
            for(let i of inputs) {
                let label = i.Label;
                
                if(toReturn.find(t=>t.Label === label) === undefined) {
                    toReturn.push(i);
                }
            }
        }

        return toReturn;
    }    

    const buildInitialValidationState = () : IValidationState[] => {
        let allInputs = getInputs();
        let validationState: IValidationState[] = [];
        
        for (let i of allInputs) {
            let allStudentForInput = getStudentsForInput(i);
            for (let s of allStudentForInput) {
                validationState.push({
                    IsValid: i.IsRequired ? false : true,
                    UserInput: i,
                    Student: s
                });
            }
        }

        return validationState;
    }
    const [inputState, setInputState] = useState<IValidationState[]>(buildInitialValidationState());

    const onValidationChanged = (isValid: boolean, student: Student, input: IUserInput) => {
        inputState.find(i =>
            i.UserInput.Label === input.Label &&
            i.UserInput.BindTo === input.BindTo &&
            i.Student === student)!.IsValid = isValid;
        
        setInputState([...inputState]);
    }

    const [isPersisting, setIsPersisting] = useState<boolean>(false);

    const persistInputs = async () => {
        if (inputState.some(i => !i.IsValid)) {
            toggleHideDialog();
            return;        
        }

        setIsPersisting(true);

        var suppliedInputs = getInputs();
        var inputsToWrite : IMembershipInput[] = [];

        for(let ri of suppliedInputs) {
            let bindTo = ri.BindTo;
            var students = getStudentsForInput(ri);

            for(let s of students) {
                
                if(bindTo.startsWith("x:")) {
                    let customValue = s.CustomValues.find(c=>c.Key === bindTo.substring(2));

                    if(customValue === null || customValue === undefined) {
                        continue;
                    }
                    
                    inputsToWrite.push({
                        StudentId: s.Id,
                        CustomValues: [customValue]
                    });
                }
                else if(bindTo === "Weight") {                    
                    inputsToWrite.push({
                        StudentId:s.Id,
                        Weight: s.Weight
                    });                    
                }
                else {
                    //we only support custom values and weight inputs right now
                    setErrorMessage("Invalid binding, unable to proceed.");
                    setIsPersisting(false);
                    return;
                }                
            }
        }

        var result = await writeInputs(instance, account!, inputsToWrite);

        if(!result?.ok) {
            setErrorMessage("The server returned status code: " + result?.status);    
            setIsPersisting(false);        
            return;
        }

        onComplete();
    }

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const dialogStyles = { main: { maxWidth: 450 } };
   
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Required Fields',
        closeButtonAriaLabel: 'Close',
        subText: 'Please fill in all required fields before continuing.',
    };

    return (
        <>
            {getInputs().map((input,idx)=> 
                <Stack key={idx}>                  
                    <Stack style={{paddingBottom:10}} tokens={{childrenGap:10}}>
                        <Stack.Item align='start'>
                            <Label required={input.IsRequired}><strong>{input.Label}</strong></Label>
                            <Text variant='small'><span dangerouslySetInnerHTML={{ __html: input.Description }} /></Text>
                        </Stack.Item>
                        {getStudentsForInput(input).map((student,idx2) => 
                            <Stack.Item key={idx2} align='stretch'>
                                <StudentInput                                     
                                    input={input} 
                                    tournamentId={tournament.Id}
                                    student={student}
                                    required={input.IsRequired} 
                                    onValidationChanged={(isValid) => onValidationChanged(isValid, student, input)} />
                            </Stack.Item>
                        )}
                    </Stack>                      
                </Stack>
            )}
            <br />
            <PrimaryButton disabled={isPersisting} onClick={persistInputs}>Next</PrimaryButton>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{styles:dialogStyles}}>
                <DialogFooter>
                    <PrimaryButton onClick={toggleHideDialog} text="Ok" />
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default EventInputCollector;